import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '../index'
import { updateSolanaPublicKey } from './actions'

export default function Updater(): null {
  const dispatch = useDispatch<AppDispatch>()

  useEffect(() => {
    const handler = (publicKey: string) => {
      if (publicKey) {
        dispatch(updateSolanaPublicKey({ solanaPublicKey: publicKey.toString() }))
      } else {
        window.solana.connect()
      }
    }

    const { okxwallet, phantom } = window
    if (okxwallet?.solana?.on) {
      okxwallet.solana?.on('accountChanged', (publicKey: string) => handler(publicKey))
      okxwallet.solana?.on('disconnect', () => handler(''))
    }

    if (phantom) {
      phantom.solana.on('accountChanged', (publicKey: string) => handler(publicKey))
      phantom.solana?.on('disconnect', () => handler(''))
    }
  }, [dispatch])

  return null
}
