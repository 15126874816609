import React, { createContext, useEffect, useState } from 'react'
import { useActiveWeb3React } from '../../hooks'
import { Sushi } from '../../sushi'

export interface YamContext {
  yam?: typeof Sushi
}

export const Context = createContext<YamContext>({
  yam: undefined,
})

declare global {
  interface Window {
    yamsauce: any
  }
}

const YamProvider: React.FC<{ children: React.ReactNode }> = ({ children }: { children: React.ReactNode }) => {
  const { account, chainId, library } = useActiveWeb3React()
  const [yam, setYam] = useState<any>()
  const ethereum = library?.provider

  // @ts-ignore
  window.yam = yam
  // @ts-ignore
  window.eth = ethereum

  useEffect(() => {
    if (account && chainId && ethereum) {
      console.log('===ethereum===', ethereum)
      const yamLib = new Sushi(ethereum, chainId, false, {
        defaultAccount: (ethereum as any)?.selectedAddress,
        defaultConfirmations: 1,
        autoGasMultiplier: 1.5,
        testing: false,
        defaultGas: '6000000',
        defaultGasPrice: '1000000000000',
        accounts: [],
        ethereumNodeTimeout: 10000,
      })
      setYam(yamLib)
      window.yamsauce = yamLib
    }
  }, [account, chainId, ethereum])

  return <Context.Provider value={{ yam }}>{children}</Context.Provider>
}

export default YamProvider
