import { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { AppState } from 'state'
import { updateUserInfo } from './actions'

export function useUserInfo(): AppState['twitter']['userInfo'] {
  return useSelector((state: AppState) => state && state.twitter && state.twitter.userInfo)
}

export function useUpdateUserInfo(): (userInfo: any) => void {
  const dispatch = useDispatch()

  return useCallback(
    (userInfo: any) => {
      dispatch(updateUserInfo({ userInfo }))
    },
    [dispatch]
  )
}
