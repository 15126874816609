import React from 'react'
import styled, { css } from 'styled-components'

interface DividerProps {
  isHorizontal?: boolean
  width?: string
  height?: string
  borderWidth?: number
  color?: string
  marginTop?: number
  marginLeft?: number
  marginRight?: number
  marginBottom?: number
  margin?: string
}

const Base = styled.div<DividerProps>`
  ${({ marginTop }) =>
    marginTop &&
    css`
      margin-top: ${marginTop}px;
    `}
  ${({ marginLeft }) =>
    marginLeft &&
    css`
      margin-left: ${marginLeft}px;
    `}
  ${({ marginRight }) =>
    marginRight &&
    css`
      margin-right: ${marginRight}px;
    `}
  ${({ marginBottom }) =>
    marginBottom &&
    css`
      margin-bottom: ${marginBottom}px;
    `}
  ${({ margin }) =>
    margin &&
    css`
      margin: ${margin};
    `}
`

const Horizontal = styled(Base)`
  border-top: ${({ borderWidth }) => (borderWidth ? borderWidth : 1)}px solid
    ${({ theme, color }) => (color ? color : theme.btnBg1)};
  width: ${({ width }) => (width ? width : '100%')};
`

const Vertical = styled(Base)`
  border-left: ${({ borderWidth }) => (borderWidth ? borderWidth : 1)}px solid
    ${({ theme, color }) => (color ? color : theme.btnBg1)};
  height: ${({ height }) => (height ? height : '100%')};
`

export default function Divider({
  isHorizontal = true,
  width,
  height,
  borderWidth,
  color,
  marginTop,
  marginLeft,
  marginRight,
  marginBottom,
  margin,
}: DividerProps) {
  return isHorizontal ? (
    <Horizontal
      width={width}
      borderWidth={borderWidth}
      color={color}
      marginTop={marginTop}
      marginLeft={marginLeft}
      marginRight={marginRight}
      marginBottom={marginBottom}
      margin={margin}
    />
  ) : (
    <Vertical
      height={height}
      borderWidth={borderWidth}
      color={color}
      marginTop={marginTop}
      marginLeft={marginLeft}
      marginRight={marginRight}
      marginBottom={marginBottom}
      margin={margin}
    />
  )
}
