// import fetch from 'isomorphic-unfetch'
import { localStore, removeAccountToken, getAccountToken } from 'utils/tools'

interface Options {
  headers?: {
    [key: string]: any
  }
  ignoreToken?: boolean
  account?: string
}

interface RequestOptions {
  mode: string
  method: string
  headers: any
}

const requestInterceptor = (url: string, opts: any) => {
  return [url, opts]
}

const responseInterceptor = (res: unknown) => {
  return res
}

const responseResultInterceptor = (res: { code: number; message: string; data: any }) => {
  if (res.code === 403) {
    const account = localStore.get('Bakeryswap.account')
    removeAccountToken(account)
  }
  return res
}

const success = (code: number) => (code >= 200 && code < 300) || code === 401

const checkStatus = async (response: any) => {
  if (success(response.status)) return response
  try {
    const resError = await response.json()
    const error: any = new Error(response.statusText || response.status)
    error.errorMsg = resError
    throw error
  } catch (error: any) {
    error.code = response.status
    throw error
  }
}

const fetchWithTimeout = (url: string, options: any, timeout = 120000) => {
  return Promise.race([
    fetch(url, options),
    new Promise((_, reject) => setTimeout(() => reject({ message: 'Network error' }), timeout)),
  ])
}

const request = async (url: string, opts: any) => {
  try {
    ;[url, opts] = requestInterceptor(url, opts)
    const res: any = responseInterceptor(await fetchWithTimeout(url, opts))
    await checkStatus(res)
    const result = await res.json()
    return responseResultInterceptor(result)
  } catch (error) {
    console.log('error', error)
    // throw error
  }

  throw new Error('Unexpected error occurred')
}

const generateHeader = (options: Options) => {
  // Add token to headers
  const token = getAccountToken(options.account)
  const auth =
    token && !options.ignoreToken
      ? {
          Authorization: `Bearer ${token}`,
        }
      : {}
  const { headers } = options
  const defaultHeader = {
    'Content-Type': 'application/json',
    ...auth,
  }
  const opts: RequestOptions = {
    method: 'GET',
    mode: 'cors',
    headers: { ...defaultHeader, ...headers },
  }
  return { opts }
}

export const get = (url: string, options?: any) => {
  options = options || {}

  const { opts } = generateHeader(options)

  return request(url, opts)
}

export const post = (url: string, params?: any, options = {}) => {
  let { opts } = generateHeader(options)
  opts = Object.assign(opts, {
    method: 'POST',
    body: JSON.stringify(params),
  })

  return request(url, opts)
}

export const del = (url: string, options = {}) => {
  let { opts } = generateHeader(options)
  opts = Object.assign(opts, {
    method: 'DELETE',
  })

  return request(url, opts)
}

export const postFormdata = (url: string, params?: any, options = {}) => {
  let { opts } = generateHeader(options)
  const token = getAccountToken()
  const auth = token
    ? {
        Authorization: `Bearer ${token}`,
      }
    : {}
  opts = Object.assign(opts, {
    method: 'POST',
    body: params,
    headers: {
      ...auth,
    },
  })

  return request(url, opts)
}
