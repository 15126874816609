// @ts-nocheck
import { Any, ErrorResponse, JsonRpcFailure, JsonRpcRequest, JsonRpcSuccess } from '../../w3w-types'

import { INTERNAL_ERROR, SERVER_ERROR } from './constants'
import { getError, getErrorByCode, isReservedErrorCode } from './error'

export function payloadId(): number {
  const date = Date.now() * Math.pow(10, 3)
  const extra = Math.floor(Math.random() * Math.pow(10, 3))
  return date + extra
}

export function uuid(): string {
  const result: string = ((a?: Any, b?: Any) => {
    for (
      b = a = '';
      a++ < 36;
      b += (a * 51) & 52 ? (a ^ 15 ? 8 ^ (Math.random() * (a ^ 20 ? 16 : 4)) : 4).toString(16) : '-'
    ) {
      // empty
    }
    return b
  })()
  return result
}

export function formatJsonRpcRequest<T = any>(method: string, params: T, id?: number): JsonRpcRequest<T> {
  return {
    id: id || payloadId(),
    jsonrpc: '2.0',
    method,
    params: params || [],
  } as JsonRpcRequest<T>
}

export function formatJsonRpcResult<T = any>(id: number, result: T): JsonRpcSuccess<T> {
  return {
    id,
    jsonrpc: '2.0',
    result,
  }
}

export function formatJsonRpcError(id: number, error?: string | ErrorResponse, data?: string): JsonRpcFailure {
  return {
    id,
    jsonrpc: '2.0',
    error: formatErrorMessage(error, data),
  }
}

export function formatErrorMessage(error?: string | ErrorResponse, data?: string): ErrorResponse {
  if (typeof error === 'undefined') {
    return getError(INTERNAL_ERROR)
  }
  if (typeof error === 'string') {
    error = {
      ...getError(SERVER_ERROR),
      message: error,
    }
  }
  if (typeof data !== 'undefined') {
    error.data = data
  }
  if (isReservedErrorCode(error.code)) {
    error = getErrorByCode(error.code)
  }
  return error
}
