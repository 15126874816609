import React, { useCallback, useState, useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'

import IconArrowBottom from '../../assets/ido/icon_arrow_bottom.svg'

interface SelectProps {
  children?: React.ReactNode
  selectedValue?: any
  height?: number
  margin?: string
  top?: string
  right?: string
  bottom?: string
  left?: string
  minWidth?: number
  maxHeight?: number
  showList?: boolean
  bg?: string
  radius?: string
  pd?: string
  color?: string
  icon?: string
  listRadius?: string
  img?: string
}

const SelectWrapper = styled.div<SelectProps>`
  position: relative;
  margin: ${({ margin }) => (margin ? margin : '0')}px;
  display: flex;
  align-items: center;
  cursor: pointer;
  height: ${({ height }) => (height ? height : 32)}px;
  user-select: none;
  padding: ${({ pd }) => (pd ? pd : '0px')};
  background: ${({ bg }) => (bg ? bg : '')};
  border-radius: ${({ radius }) => (radius ? radius : '0px')};
  border: 1px solid
    ${({ img, bg, showList, theme }) =>
      img ? (showList ? theme.bsBorder7 : theme.bsBg32) : bg ? theme.bsBorder4 : 'none'};
  &:hover {
    border: 1px solid ${({ img, theme }) => (img && !theme.darkMode ? theme.bsBorder7 : 'none')};
    box-shadow: ${({ img, theme }) => (img && !theme.darkMode ? '0px 2px 12px rgba(255, 221, 171, 0.6)' : 'none')};
  }
`
const HideInput = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  opacity: 0;
  width: 100%;
  height: 100%;
`
const SelectedItem = styled.div<SelectProps>`
  color: ${({ theme }) => theme.primary1};
  font-weight: 700;
  font-size: 16px;
  width: 100%;
  font-family: 'Roboto-Bold', 'Inter var', sans-serif;
  color: ${({ color }) => (color ? color : '')}!important;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 15px;
  `}
`
export const MintSelectedItem = styled(SelectedItem)`
  margin-left: 13px;
  font-family: 'Roboto-Bold', 'Inter var', sans-serif;
  font-style: normal;
  font-size: 14px;
  color: ${({ theme }) => (theme ? theme.bsText7 : '')};
  img {
    margin-right: 9px;
    width: 18px;
    height: 18px;
    vertical-align: middle;
  }
`
const IconArrow = styled.img`
  margin-left: 16px;
`
const ListWrapper = styled.div<SelectProps>`
  position: absolute;
  top: ${({ top, bottom }) => (top ? top : bottom ? 'initial' : '100%')};
  bottom: ${({ top, bottom }) => (top || !bottom ? 'initial' : bottom)};
  left: ${({ left, right }) => (left ? left : right ? 'initial' : '0px')};
  right: ${({ left, right }) => (left || !right ? 'initial' : right)};
  padding: ${({ img }) => (img ? '0 12px' : '0')};
  background: ${({ bg, theme }) => (bg ? bg : theme.bsBg27)};
  border-radius: ${({ listRadius }) => (listRadius ? listRadius : '5px')};
  box-shadow: ${({ showList, img }) =>
    showList
      ? img
        ? '0px 4px 24px rgba(54, 37, 30, 0.19)'
        : '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)'
      : 'none'};
  min-width: ${({ minWidth }) => (minWidth ? minWidth : 52)}px;
  max-width: 300px;
  width: ${({ img }) => (img ? '100%' : 'auto')};
  max-height: ${({ showList, maxHeight }) => (!showList ? 0 : maxHeight ? maxHeight : 300)}px;
  overflow-x: hidden;
  overflow-y: auto;
  transition: all 0.3s;
  z-index: 5;
  opacity: 1;
`
const UlElement = styled.ul`
  margin: 0;
  padding: 8px 0;
  width: 100%;
  font-family: 'Roboto-Bold', 'Inter var', sans-serif;
`

export const curClassName = 'CURSELEST'

export const OptionItem = styled.li.attrs({
  curClassName,
})<{ wd?: string; pd?: string; size?: string; weight?: string; select?: string; img?: string }>`
  &.${curClassName} {
    background: ${({ img, select, theme }) => (img ? theme.btnBg3 : select ? select : theme.bsBg39)};
    .img-right {
      visibility: visible;
    }
  }

  display: flex;
  justify-content: space-between;
  color: ${({ img, theme }) => (img ? theme.bsText22 : theme.bsText7)};
  font-size: ${({ size }) => (size ? size : '14px')};
  line-height: ${({ img }) => (img ? '46px' : '24px')};
  padding: ${({ pd }) => (pd ? pd : '6px 16px')};
  width: ${({ wd, img }) => (img ? '100%' : wd ? wd : 'auto')};
  font-weight: ${({ weight }) => (weight ? weight : 'normal')};
  &:hover {
    border-radius: ${({ img }) => (!img ? 0 : '8px')};
    background: ${({ theme }) => theme.bsBg39};
    color: ${({ img, theme }) => (img ? theme.bsText22 : theme.bsText7)};
  }
  .img-right {
    width: 24px;
    height: 24px;
    visibility: hidden;
  }
`

export default function Select({
  children,
  selectedValue,
  height,
  margin,
  top,
  right,
  bottom,
  left,
  minWidth,
  maxHeight,
  bg,
  radius,
  pd,
  color,
  icon,
  listRadius,
}: SelectProps) {
  const theme = useContext(ThemeContext)
  const [showList, setShowList] = useState(false)
  const onFocus = useCallback(() => {
    setShowList(true)
  }, [])
  const onBlur = useCallback(() => {
    setShowList(false)
  }, [])

  return (
    <SelectWrapper
      height={height}
      bg={bg}
      radius={radius}
      pd={pd}
      margin={margin}
      showList={showList}
      img={selectedValue.img}
    >
      {selectedValue.img ? (
        <MintSelectedItem color={color}>
          {!['collection'].includes(selectedValue.img) ? <img src={selectedValue.img} alt="" /> : ''}
          {selectedValue.title}
        </MintSelectedItem>
      ) : (
        <SelectedItem color={color}>{selectedValue}</SelectedItem>
      )}
      <IconArrow src={icon || IconArrowBottom} alt="icon" />
      <HideInput onFocus={onFocus} onBlur={onBlur} readOnly />
      <ListWrapper
        top={top}
        right={right}
        bottom={bottom}
        left={left}
        minWidth={minWidth}
        maxHeight={maxHeight}
        bg={selectedValue.img ? theme.bsBg38 : bg}
        showList={showList}
        listRadius={listRadius}
        img={selectedValue.img}
      >
        <UlElement>{children}</UlElement>
      </ListWrapper>
    </SelectWrapper>
  )
}
