import styled from 'styled-components'
import React, { useCallback, useEffect, useState } from 'react'
import InfiniteScroll from 'components/InfiniteScroll'
import Header from 'components/NFTSwap/Header'
import { isMobile } from 'react-device-detect'
import Operation from 'components/NFTSwap/Operation'
import { ReactComponent as CartIcon } from 'assets/svg/cart.svg'
import Modal from 'components/NFTSwap/Modal'
import { fetchNFTList } from 'service/nftswap'
import { useActiveWeb3React, useInterval, useSwitchNetwork } from 'hooks'
import { Affix } from 'antd'
import { useAddPopup } from 'state/application/hooks'

const Body = styled.div`
  background-color: ${({ theme }) => (theme.darkMode ? '#221812' : '#FFF3E0')};
  width: 100%;
  height: 100%;
  flex: 1;
`
const Banner = styled.img`
  width: 100%;
  height: 418px;
  object-fit: cover;
  ${({ theme }) => theme.mediaWidth.upToBigerLarge`
    height: 427px;
  `};
  ${({ theme }) => theme.mediaWidth.upToInfoLarge`
    height: 376px;
  `};
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    height: 106px;
  `};
`

const Wrapper = styled.div`
  width: 100%;
  margin-top: -91px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  padding: 0 71px;
  ${({ theme }) => theme.mediaWidth.upToBigerLarge`
    margin-top: -83px;
    padding: 0 65px;
  `};
  ${({ theme }) => theme.mediaWidth.upToInfoLarge`
    margin-top: -73px;
    padding: 0 57px;
  `};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: -40px;
    padding: 0;
  `}
`

const Container = styled.div`
  width: 100%;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    padding: 0 16px;
  `}
`

const Tabs = styled.div`
  display: flex;
  align-items: center;
  grid-column-gap: 57px;
  font-family: 'Roboto-Bold', 'Inter var', sans-serif;
  position: relative;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    grid-column-gap: 34px;
  `}
`

const Tab = styled.div`
  color: ${({ theme }) => (theme.darkMode ? 'rgba(255, 255, 255, 0.6)' : '#C58561')};
  font-size: 16px;
  &.active {
    color: ${({ theme }) => (theme.darkMode ? '#fff' : '#AC562A')};
  }
  position: relative;
  img {
    position: absolute;
    top: -16px;
    right: -51px;
    width: 67px;
    height: 20px;
  }
  ${({ theme }) => theme.mediaWidth.upToBigerLarge`
    font-size: 15px;
  `};
  ${({ theme }) => theme.mediaWidth.upToInfoLarge`
    font-size: 13px;
  `};
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    font-size: 14px;
    img {
      top: -16px;
      right: -40px;
      width: 56px;
      height: 20px;
    }
  `}
`

/* transform: translateX(${({ activeIndex }) => activeIndex === 0 ? 0 : activeIndex === 1 ? 100 : 205}px); */
const Line = styled.div<{ hiddenBefore?: boolean }>`
  width: 100%;
  height: 1px;
  background: ${({ theme }) => (theme.darkMode ? 'rgba(255, 255, 255, 0.2)' : 'rgba(197, 133, 96, 1)')};
  margin-top: 11px;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: -3px;
    z-index: 1;
    width: 49px;
    height: 4px;
    border-radius: 2px;
    background: ${({ theme }) => (theme.darkMode ? '#428EF1' : '#AC552A')};
    ${({ hiddenBefore }) => hiddenBefore && 'display: none;'}
  }
  ${({ theme }) => theme.mediaWidth.upToBigerLarge`
    &::before {
      width: 45px;
    }
  `};
  ${({ theme }) => theme.mediaWidth.upToInfoLarge`
    &::before {
      width: 39px;
    }
  `};
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    margin-top: 7px;
    &::before {
      width: 39px;
  `}
`

const NFTWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-top: 32px;
  position: relative;
  ${({ theme }) => theme.mediaWidth.upToBigerLarge`
    margin-top: 29px;
  `};
  ${({ theme }) => theme.mediaWidth.upToInfoLarge`
    margin-top: 26px;
  `};
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    margin-top: 22px;
  `}
`

const NFTCardWrapper = styled.div`
  flex: 1;
  position: relative;
  padding: 20px;
  margin-left: -20px;
  margin-right: -20px;
  margin-top: -20px;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    height: 100%;
    margin-left: -9px;
    margin-right: -9px;
    margin-top: -9px;
    padding: 9px;
    display: flex;
    justify-content: center;
  `}
`

const Content = styled.div<{ hidden: boolean }>`
  /* display: flex;
  flex-wrap: wrap; */
  display: grid;
  grid-column-gap: 16px;
  grid-row-gap: 15px;
  position: relative;
  --template-reduced-columns: 0;
  --template-reduced-columns-multiplier: 1;
  --template-columns: ${({ hidden }) => (hidden ? 6 : 5)};
  grid-auto-rows: minmax(0px, 1fr);
  grid-template-columns: repeat(
    calc(var(--template-columns) - (var(--template-reduced-columns) * var(--template-reduced-columns-multiplier))),
    minmax(0, 1fr)
  );

  ${({ theme }) => theme.mediaWidth.upToLarge`
    --template-columns: 3;
  `};
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    grid-column-gap: 9px;
    grid-row-gap: 9px;
    flex: 1;
    --template-columns: 2;
  `}
`

const NFTCard = styled.div`
  border-radius: 11px;
  padding: 9px 9px 10px;
  flex-shrink: 0;
  background: ${({ theme }) => (theme.darkMode ? '#2D2E32' : 'linear-gradient(180deg, #fff 0%, #fff 100%)')};
  position: relative;
  ${({ theme }) => theme.mediaWidth.upToBigerLarge`
    padding: 7px 7px 9px;
  `};
  ${({ theme }) => theme.mediaWidth.upToInfoLarge`
    padding: 6px 5px 7px;
  `};
  &:hover {
    .add_cart {
      display: flex;
    }
  }
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    box-shadow: 0px 0px 6.597249984741211px rgba(0, 0, 0, 0.10);
    padding: 10px;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 11px;
    .add_cart {
      display: flex;
    }
  `}
`

const ImgWrapper = styled.div`
  border-radius: 7px;
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 100%;
  img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
  }
`

const AddCartWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  display: none;
  width: 100%;
  height: 100%;
  align-items: flex-end;
  cursor: pointer;
`

const AddCart = styled.div`
  width: 100%;
  height: 37px;
  background: #ac552a;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-family: Baloo Da, 'Inter var', sans-serif;
  font-size: 14px;
  font-weight: 600;
  ${({ theme }) => theme.mediaWidth.upToBigerLarge`
    height: 34px;
    font-size: 13px;
  `};
  ${({ theme }) => theme.mediaWidth.upToInfoLarge`
    height: 30px;
    font-size: 12px;
  `};
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    height: 24px;
    svg {
      width: 8px;
      height: 8px;
      margin-left: 6px;
    }
  `}
`

const ChooseWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: 2px solid #ac552a;
  border-radius: 7px;
  display: none;
  justify-content: flex-end;
  align-items: flex-end;
  cursor: pointer;
  svg {
    margin-right: -2px;
    margin-bottom: -2px;
  }
  &.choosed {
    display: flex;
  }
  ${({ theme }) => theme.mediaWidth.upToBigerLarge`
    svg {
      width: 45px;
      height: 24px;
    }
  `};
  ${({ theme }) => theme.mediaWidth.upToInfoLarge`
    svg {
      width: 40px;
      height: 21px;
    }
  `};
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    border-width: 2px;
    border-radius: 7px;
    svg {
      margin-right: -1px;
      margin-bottom: -1px;
      width: 50px;
      height: 25px;
    }
  `}
`

const Id = styled.div`
  color: ${({ theme }) => (theme.darkMode ? '#fff' : '#722F0D')};
  font-family: Baloo Da, 'Inter var', sans-serif;
  font-size: 14px;
  margin-top: 4px;
  padding-bottom: 3px;
  border-bottom: 1px solid rgba(235, 235, 235, 0.6);
  width: 100%;
  ${({ theme }) => theme.mediaWidth.upToBigerLarge`
    margin-top: 19px;
    font-size: 16px;
    padding-bottom: 12px;
  `};
  ${({ theme }) => theme.mediaWidth.upToInfoLarge`
    margin-top: 17px;
    font-size: 14px;
    padding-bottom: 10px;
  `};
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    font-size: 12px;
    margin-top: 15px;
    padding-bottom: 7px;
  `}
`

const Cart = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 17px;
  border: 1px solid ${({ theme }) => (theme.darkMode ? '#9A9A9A' : 'rgba(0, 0, 0, 0.20)')};
  width: 60px;
  height: 60px;
  z-index: 5;
  background: ${({ theme }) => (theme.darkMode ? '#2D2E32' : '#FFF')};
  cursor: pointer;
  svg {
    width: 27px;
    height: 27px;
    path {
      fill: ${({ theme }) => (theme.darkMode ? '#fff' : '#000')};
    }
  }
  ${({ theme }) => theme.mediaWidth.upToBigerLarge`
    height: 66px;
    width: 66px;
    svg {
      width: 25px;
      height: 25px;
    }
  `};
  ${({ theme }) => theme.mediaWidth.upToInfoLarge`
    height: 58px;
    width: 58px;
    svg {
      width: 22px;
      height: 22px;
    }
  `};
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    width: 38px;
    height: 38px;
    border-radius: 11px;
    position: absolute;
    top: -15px;
    right: 0;
    svg {
      width: 17px;
      height: 17px;
    }
  `}
`

const Number = styled.div`
  background-color: #ac562a;
  border-radius: 50%;
  font-weight: 600;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  font-size: 18px;
  width: 25px;
  height: 25px;
  top: 6px;
  right: 6px;
  ${({ theme }) => theme.mediaWidth.upToBigerLarge`
    height: 23px;
    width: 23px;
    font-size: 16px;
  `};
  ${({ theme }) => theme.mediaWidth.upToInfoLarge`
    height: 20px;
    width: 20px;
    font-size: 14px;
  `};
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    width: 16px;
    height: 16px;
    top: 4px;
    right: 4px;
    font-size: 12px;
  `}
`

const LIMIT = 30
export default function NFTSwap() {
  const { chainId } = useActiveWeb3React()
  const [list, setList] = useState<any[]>([])
  const [loading, setLoading] = useState(false)
  const [loadedAll, setLoadedAll] = useState(false)
  const [cart, setCart] = useState<any[]>([])
  const [chooseNFT, setChooseNFT] = useState({ id: 'null', nftId: '' })
  const [isOpen, setIsOpen] = useState(false)
  const [hidden, setHidden] = useState(false)
  const addPopup = useAddPopup()
  useSwitchNetwork()

  const fetchList = useCallback(
    async (take?: number, skip?: number) => {
      const { data: res } = await fetchNFTList(chainId as number, {
        take: take || LIMIT,
        skip: skip === 0 ? 0 : list.length,
      })
      setLoading(false)
      if (res.data.length < LIMIT) {
        setLoadedAll(true)
      }
      if (take) {
        const arr = [...list]
        arr.splice(0, res.data.length, ...res.data)
        setList(arr)
      } else {
        setList([...list, ...res.data])
      }
    },
    [chainId, list]
  )

  useEffect(() => {
    fetchList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useInterval(() => {
    fetchList(list.length, 0)
  }, 10000)

  useEffect(() => {
    if (!loading) return
    fetchList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  const loadmore = useCallback(() => {
    setLoading(true)
  }, [])

  const box = document.querySelector('nav') as HTMLElement

  const [hiddenFold, setHiddenFold] = useState(false)
  const resizeObserver = new ResizeObserver(() => {
    if (box.clientWidth < 200) {
      setHiddenFold(true)
    } else {
      setHiddenFold(false)
    }
  })
  resizeObserver.observe(box)

  return (
    <Body>
      <Banner src={`/images/nftswap/banner.png`} />
      <Wrapper>
        <Header />
        <Container>
          <Tabs>
            <Tab className="active">Items</Tab>
            <Tab>
              Chart
              <img src={`/images/nftswap/coming-soon.png`} />
            </Tab>
            <Tab>
              History
              <img style={{ right: -41 }} src="/images/nftswap/coming-soon.png" />
            </Tab>
            {isMobile && (
              <Cart onClick={() => setIsOpen(true)}>
                <CartIcon />
                <Number>{cart.length}</Number>
              </Cart>
            )}
          </Tabs>
          <Line />
          <NFTWrapper>
            <NFTCardWrapper>
              <Content hidden={hidden}>
                <InfiniteScroll
                  selector={isMobile ? '' : '#BodyContainer'}
                  loading={loading}
                  loadedAll={loadedAll}
                  loadmore={loadmore}
                  isHiddenLoading={true}
                  hiddenFold={hiddenFold}
                >
                  {list.map((nft) => {
                    return (
                      <NFTCard
                        key={nft.id}
                        onClick={() => {
                          if (isMobile) {
                            setChooseNFT(nft)
                          }
                        }}
                      >
                        <ImgWrapper>
                          <img
                            src={`https://ipfs.io/ipfs/bafybeifyulmqraxstr4ckwptmthff2phtxcxld3osuoi5y62eozezlddvq/Doggy${nft.nftId}.gif`}
                          />
                          <AddCartWrapper
                            className={`${
                              (isMobile ? chooseNFT.id === nft.id : cart.every((item) => item.id !== nft.id))
                                ? 'add_cart'
                                : ''
                            }`}
                            onClick={(e) => {
                              e.stopPropagation()
                              if (isMobile) {
                                setChooseNFT({
                                  id: 'null',
                                  nftId: '',
                                })
                              }
                              if (cart.length === 50) {
                                addPopup({
                                  txn: {
                                    hash: '',
                                    success: false,
                                    summary: 'Max 50 NFTs per transaction',
                                  },
                                })
                                return
                              }
                              setCart([...cart, { ...nft }])
                            }}
                          >
                            <AddCart>
                              Add to Cart
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="12"
                                height="12"
                                viewBox="0 0 12 12"
                                fill="none"
                                style={{ marginLeft: 9, marginTop: -1 }}
                              >
                                <path
                                  d="M6.52775 6.58467H10.1573C10.4915 6.58467 10.7623 6.31393 10.7623 5.97984C10.7623 5.64574 10.4914 5.37487 10.1573 5.37487H6.52773V1.74511C6.52773 1.41099 6.25689 1.14014 5.92274 1.14014C5.58862 1.14014 5.31776 1.41099 5.31776 1.74511V5.37469H1.68798C1.35386 5.37469 1.08301 5.64557 1.08301 5.97968C1.08301 6.3138 1.35386 6.58465 1.68798 6.58465H5.31778V10.2145C5.31778 10.5485 5.58866 10.8194 5.92277 10.8194C6.25689 10.8194 6.52775 10.5485 6.52775 10.2144V6.58467Z"
                                  fill="#F7F7F7"
                                  stroke="white"
                                  strokeWidth="0.725946"
                                />
                              </svg>
                            </AddCart>
                          </AddCartWrapper>
                          <ChooseWrapper
                            className={`${cart.filter((item) => item.id === nft.id).length ? 'choosed' : ''}`}
                            onClick={() => setCart([...cart.filter((item) => item.id !== nft.id)])}
                          >
                            <svg
                              width="50"
                              height="26"
                              viewBox="0 0 50 26"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M7.35359 8.50797C9.61475 3.3396 14.721 0 20.3624 0H50.6219V25.3095H0.00292969L7.35359 8.50797Z"
                                fill="#AC552A"
                              />
                              <path
                                d="M34.1664 6.79649C34.6853 6.29004 35.5165 6.30024 36.0227 6.81927L36.6064 7.41767C37.1125 7.93647 37.1023 8.76723 36.5836 9.27343L26.6382 18.9801C26.1323 19.4739 25.3262 19.4783 24.815 18.9901L20.4972 14.8671C19.9729 14.3664 19.9538 13.5355 20.4546 13.0113L21.0317 12.4071C21.5324 11.8829 22.3631 11.8638 22.8873 12.3644L24.7886 14.1802C25.2999 14.6684 26.106 14.664 26.6119 14.1702L34.1664 6.79649Z"
                                fill="white"
                              />
                            </svg>
                          </ChooseWrapper>
                        </ImgWrapper>
                        <Id>Doggy #{nft.nftId}</Id>
                      </NFTCard>
                    )
                  })}
                </InfiniteScroll>
              </Content>
            </NFTCardWrapper>
            {!isMobile && (
              <Affix offsetTop={32} target={() => document.getElementById('BodyContainer')}>
                <Operation
                  hidden={hidden}
                  cart={cart}
                  setCart={setCart}
                  onClose={() => setHidden(true)}
                  refreshNFTsFn={() => {
                    fetchList(list.length, 0)
                    if (list.length < LIMIT - 30 && loadedAll) setLoadedAll(false)
                  }}
                />
              </Affix>
            )}
            {!isMobile && hidden && (
              <Affix
                offsetTop={32}
                target={() => document.getElementById('BodyContainer')}
                style={{ position: 'absolute', top: -12, right: 0 }}
              >
                <Cart onClick={() => setHidden(false)}>
                  <CartIcon />
                  <Number>{cart.length}</Number>
                </Cart>
              </Affix>
            )}
          </NFTWrapper>
          {isMobile && (
            <Modal isOpen={isOpen} onDismiss={() => setIsOpen(false)} maxHeight={90}>
              <Operation
                cart={cart}
                setCart={setCart}
                onClose={() => setIsOpen(false)}
                refreshNFTsFn={() => {
                  fetchList(list.length, 0)
                  if (list.length < LIMIT - 30 && loadedAll) setLoadedAll(false)
                }}
              />
            </Modal>
          )}
        </Container>
      </Wrapper>
    </Body>
  )
}
