import React, { useContext, useState, useCallback, useEffect, useRef } from 'react'
import styled, { ThemeContext } from 'styled-components'

// components
import { AutoRow } from 'components/Row'
import ExchangePets from './ExchangePets'
import { ButtonConfirmed } from 'components/Button'
import Loader from 'components/Loader'
// import WhitelistModal from './WhitelistModal'
// import CheckWL from './CheckWL'

import BigNumber from 'bignumber.js'

// hooks
import { useActiveWeb3React } from 'hooks'
import { useAddPopup, useWalletModalToggle } from 'state/application/hooks'
import { useTokenBalance } from 'state/wallet'
// import useHandleIdo from 'hooks/ido/useHandleIdo'
import useApproveLaunch from 'hooks/ido/useApproveLaunch'
import useAllowanceLaunch from 'hooks/ido/useAllowanceLaunch'

import { IDOTYPE } from 'constants/ido'
// import useModal from 'hooks/bakery/useModal'
import { FnContext } from 'contexts/fnCallback'

import { getWeb3 } from 'utils/web3'
import { getWhitelist } from 'service/launchpad'
import {
  NETWORK_LABELS,
  NETWORKk_RPC_URL,
  NETWORKk_URL_MAP,
  TO_DEV_CHAINID,
  TO_MASTER_CHAINID,
  SUPPORTID,
  IDTOKEN,
  BAKERY_HOST_ORG,
} from 'constants/pet'
import { StorageEmitter, formatTime, getAccountToken } from 'utils/tools'
import { useLogin } from 'hooks/useLogin'
import { contractAddresses } from 'sushi/lib/constants'
import BRIDGE_ABI from 'sushi/lib/abi/stake.json'
import { parseUnits } from '@ethersproject/units'
import { TransactionReceipt } from 'web3-eth'
import useStaked from 'hooks/useStaked'
import CheckWL from './CheckWL'
import idoLaunch from 'hooks/ido/idoLaunch'
// import InvitationCode from './InvitationCode'

const Exchange = styled.div`
  font-family: 'Roboto-Regular', 'Inter var', sans-serif;
  margin: 21px 0 42px;
  padding-bottom: 32px;
  border-bottom: 1px solid ${({ theme }) => theme.bsBg45};
  letter-spacing: 0.01em;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin: 24px 0 20px;
    // padding-bottom: 30px;
  `}
`
const BtnConfirmed = styled(ButtonConfirmed)`
  width: 100%;
  height: 54px;
  margin: 19px auto 0;
  padding: 0;
  border-radius: 12px;
  font-family: Baloo Da, 'Inter var', sans-serif;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0em;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin: 20px auto 0;
    height: 53px;
    font-size: 16px;
  `}
`
const Des = styled.div`
  font-family: 'Roboto-Regular', 'Inter var', sans-serif;
  font-size: 14px;
  font-weight: 300;
  line-height: 19px;
  letter-spacing: 0.01em;
  white-space: pre-line;
  color: ${({ theme }) => theme.bsText38};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 14px;
  `}
`

const Summary = styled.div`
  display: flex;
  margin: 10px 0 0;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #c58560;
`

interface IdoSupportProps {
  type: any
  options: any
  dataInfo: any
  defaultSymbol?: any
  platform?: string
  brc20?: any
}

export default function SupportCard({
  type,
  options,
  dataInfo,
  defaultSymbol,
  platform = 'bitcoincats',
}: IdoSupportProps) {
  const { account, chainId, library } = useActiveWeb3React()
  const addPopup = useAddPopup()
  const [, loginFn] = useLogin()
  const toggleWalletModal = useWalletModalToggle()
  const theme = useContext(ThemeContext)
  const callback: any = useContext(FnContext)

  const [baseAsset, setBaseAsset] = useState(defaultSymbol ? defaultSymbol : options[0])
  const [value, setValue] = useState<string | number>('')

  // allowance
  const updatePetAmount = () => {}

  const allowanceLaunch = useAllowanceLaunch(baseAsset.symbol.toLowerCase(), type)
    .div(new BigNumber(10).pow(18))
    ?.toNumber()
  const updateValue = useCallback((value) => {
    setValue(value)
  }, [])

  const switchNetwork = useCallback(async () => {
    const toChainId = BAKERY_HOST_ORG ? TO_MASTER_CHAINID : TO_DEV_CHAINID
    const ethereum: any = library?.provider
    if (chainId === toChainId[baseAsset.symbol.toLowerCase()]) return
    const web3 = getWeb3(toChainId[baseAsset.symbol.toLowerCase()])
    await ethereum
      .request({
        method: 'wallet_switchEthereumChain',
        params: [
          {
            chainId: web3.utils.toHex(toChainId[baseAsset.symbol.toLowerCase()]),
          },
        ],
      })
      .then(() => {
        // window.location.reload()
      })
      .catch(async (e: any) => {
        if ((e as any).code === 4902 || (e as any).code === -32603) {
          ethereum
            .request({
              method: 'wallet_addEthereumChain',
              params: [
                {
                  chainId: web3.utils.toHex(toChainId[baseAsset.symbol.toLowerCase()]),
                  chainName: NETWORK_LABELS[toChainId[baseAsset.symbol.toLowerCase()]],
                  nativeCurrency: {
                    name: 'ETH',
                    symbol: 'ETH',
                    decimals: 18,
                  },
                  rpcUrls: [NETWORKk_RPC_URL[toChainId[baseAsset.symbol.toLowerCase()]]],
                  blockExplorerUrls: [NETWORKk_URL_MAP[toChainId[baseAsset.symbol.toLowerCase()]]],
                },
              ],
            })
            .then(() => {})
            .catch((e: any) => {
              console.log(JSON.stringify(e))
            })
        } else if ((e as any).code === 4001) return
      })
  }, [baseAsset.symbol, chainId, library?.provider])

  const updateBaseAsset = useCallback(
    async (value: any) => {
      if (!account) {
        setBaseAsset(value)
        callback(value)
        updateValue('')
        updatePetAmount()
        return
      }
      if (
        type === IDOTYPE.BUZZAI &&
        ['bakery', 'eth', 'wbtc', 'doggycoin', 'punkx', 'bnb', 'onecat'].includes(value.symbol.toLowerCase())
      ) {
        const toChainId = BAKERY_HOST_ORG ? TO_MASTER_CHAINID : TO_DEV_CHAINID
        const ethereum: any = library?.provider
        // console.log('d.symbol', value.symbol)
        if (chainId === toChainId[value.symbol.toLowerCase()]) {
          setBaseAsset(value)
          callback(value)
          updateValue('')
          updatePetAmount()
          return
        }

        const web3 = getWeb3(toChainId[value.symbol.toLowerCase()])
        await ethereum
          .request({
            method: 'wallet_switchEthereumChain',
            params: [
              {
                chainId: web3.utils.toHex(toChainId[value.symbol.toLowerCase()]),
              },
            ],
          })
          .then(() => {
            setBaseAsset(value)
            callback(value)
            updateValue('')
            updatePetAmount()
            // window.location.reload()
          })
          .catch(async (e: any) => {
            if ((e as any).code === 4902 || (e as any).code === -32603) {
              ethereum
                .request({
                  method: 'wallet_addEthereumChain',
                  params: [
                    {
                      chainId: web3.utils.toHex(toChainId[value.symbol.toLowerCase()]),
                      chainName: NETWORK_LABELS[toChainId[value.symbol.toLowerCase()]],
                      nativeCurrency: {
                        name: 'ETH',
                        symbol: 'ETH',
                        decimals: 18,
                      },
                      rpcUrls: [NETWORKk_RPC_URL[toChainId[value.symbol.toLowerCase()]]],
                      blockExplorerUrls: [NETWORKk_URL_MAP[toChainId[value.symbol.toLowerCase()]]],
                    },
                  ],
                })
                .then(() => {
                  setBaseAsset(value)
                  callback(value)
                  updateValue('')
                  updatePetAmount()
                  // window.location.reload()
                })
                .catch((e: any) => {
                  console.log(JSON.stringify(e))
                })
            } else if ((e as any).code === 4001) return
          })
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [account, type, callback, updateValue, library?.provider, chainId]
  )

  const [requestedApprovalLaunch, setRequestedApprovalLaunch] = useState(false)
  const onApproveLaunch = useApproveLaunch(baseAsset.symbol.toLowerCase(), type)
  const handleApproveLaunch = useCallback(async () => {
    if (!SUPPORTID.includes(chainId as number)) {
      updateBaseAsset({
        symbol: 'ETH',
        name: 'eth',
        title: 'ETH',
        status: 0,
      })
      return
    }
    try {
      setRequestedApprovalLaunch(true)
      const txHash = await onApproveLaunch()
      if (!txHash) {
        setRequestedApprovalLaunch(false)
      } else {
        setTimeout(() => {
          setRequestedApprovalLaunch(false)
        }, 1000)
      }
    } catch (e) {
      console.log(e)
      setRequestedApprovalLaunch(false)
    }
  }, [chainId, onApproveLaunch, updateBaseAsset])

  // Get token balance
  const balance = useTokenBalance(baseAsset.symbol.toLowerCase())

  // buy
  const [requestedIdo, setRequestedIdo] = useState(false)
  // const [isLogin, setIsLogin] = useState(false)
  const waitTransaction = useCallback(
    (txnHash: string | string[], options?: any) => {
      const interval = options && options.interval ? options.interval : 1000
      const transactionReceiptAsync = async function (
        txnHash: string,
        resolve: { (value: unknown): void; (arg0: TransactionReceipt): void },
        reject: { (reason?: any): void; (arg0: unknown): void }
      ) {
        const web3 = getWeb3(chainId as number)
        try {
          const receipt = await web3.eth.getTransactionReceipt(txnHash)
          if (!receipt) {
            setTimeout(function () {
              transactionReceiptAsync(txnHash, resolve, reject)
            }, interval)
          } else {
            resolve(receipt)
          }
        } catch (e) {
          reject(e)
        }
      }

      // Resolve multiple transactions once
      if (Array.isArray(txnHash)) {
        const promises: any[] = []
        txnHash.forEach(function (oneTxHash) {
          promises.push(waitTransaction(oneTxHash, options))
        })
        return Promise.all(promises)
      } else {
        return new Promise(function (resolve, reject) {
          transactionReceiptAsync(txnHash, resolve, reject)
        })
      }
    },
    [chainId]
  )

  useEffect(() => {
    // const token = getAccountToken(account as string)
    // if (token) {
    //   setIsLogin(true)
    //   handleFetchTime()
    // } else {
    //   setIsLogin(false)
    // }
    if (account) {
      switchNetwork()
    }
    handleFetchTime()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account])

  // useEffect(() => {
  //   StorageEmitter.on('storageSetItem', () => {
  //     const token = getAccountToken()
  //     if (token) {
  //       setIsLogin(true)
  //       handleFetchTime()
  //     }
  //   })
  //   return () => {
  //     StorageEmitter.off('storageSetItem', () => {
  //       console.log('off')
  //     })
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  const isLess = useCallback(() => {
    if (baseAsset?.min && +value < baseAsset?.min) {
      return true
    } else {
      if (
        !value ||
        !balance ||
        new BigNumber(value).lt(0) ||
        new BigNumber(balance).lt(0) ||
        new BigNumber(value).comparedTo(new BigNumber(0)) === 0 ||
        new BigNumber(balance).comparedTo(new BigNumber(0)) === 0
      ) {
        return true
      } else {
        if (
          baseAsset.symbol.toLowerCase() !== 'bnb' &&
          (new BigNumber(allowanceLaunch).lt(0) ||
            new BigNumber(allowanceLaunch).lt(value) ||
            new BigNumber(balance).lt(value))
        ) {
          return true
        } else {
          return false
        }
      }
    }
  }, [baseAsset?.min, baseAsset.symbol, value, balance, allowanceLaunch])

  const [recipient, setRecipient] = useState('')
  const onChangeRecipient = useCallback((value: string) => {
    setRecipient(value)
  }, [])

  useEffect(() => {
    if (account) {
      if (type === IDOTYPE.ONECAT && SUPPORTID.includes(chainId as number)) {
        let resetBaseAsset: any = baseAsset
        if (
          IDTOKEN['eth'].includes(chainId as number) &&
          !['eth', 'doggycoin'].includes(baseAsset.symbol.toLowerCase())
        ) {
          resetBaseAsset = options[0]
        }
        if (IDTOKEN['bakery'].includes(chainId as number) && !['bakery'].includes(baseAsset.symbol.toLowerCase())) {
          resetBaseAsset = options[2]
        }
        if (IDTOKEN['punkx'].includes(chainId as number) && !['punkx'].includes(baseAsset.symbol.toLowerCase())) {
          resetBaseAsset = options[3]
        }
        setBaseAsset(resetBaseAsset)
        callback(resetBaseAsset)
      }
      if (
        [IDOTYPE.BITLAND, IDOTYPE.BITCAT, IDOTYPE.BENDDAO, IDOTYPE.OPENSKY, IDOTYPE.BITCOINVM].includes(type) &&
        SUPPORTID.includes(chainId as number)
      ) {
        let resetBaseAsset: any = baseAsset
        if (IDTOKEN['onecat'].includes(chainId as number) && !['onecat'].includes(baseAsset.symbol.toLowerCase())) {
          resetBaseAsset = options[0]
        }
        if (IDTOKEN['bakery'].includes(chainId as number) && !['bakery'].includes(baseAsset.symbol.toLowerCase())) {
          resetBaseAsset = options[2]
        }
        setBaseAsset(resetBaseAsset)
        callback(resetBaseAsset)
      }
      if ([IDOTYPE.CATNFT].includes(type) && SUPPORTID.includes(chainId as number)) {
        let resetBaseAsset: any = baseAsset
        if (IDTOKEN['eth'].includes(chainId as number) && !['eth', 'onecat'].includes(baseAsset.symbol.toLowerCase())) {
          resetBaseAsset = options[0]
        }
        if (IDTOKEN['bakery'].includes(chainId as number) && !['bakery'].includes(baseAsset.symbol.toLowerCase())) {
          resetBaseAsset = options[2]
        }
        setBaseAsset(resetBaseAsset)
        callback(resetBaseAsset)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chainId, account, type, callback, options])

  const [leftTime, setLeftTime] = useState('')
  const [startTime, setStartTime] = useState(0)
  const [whitelistEndTime, setWhitelistEndTime] = useState(0)
  const [checkIsEnd, setCheckIsEnd] = useState(false)

  const handleFetchTime = useCallback(async () => {
    if (!account) return
    const { data: res } = await getWhitelist(chainId as number, { address: account, platform: platform })
    if (res.code === 0 && res.data?.config) {
      setCheckIsEnd(res.data?.config?.timeEnd < Date.now())
      const {
        timeOn: { whitelist },
      } = res.data.config
      if (startTime !== whitelist) {
        setStartTime(whitelist)
        setWhitelistEndTime(whitelist)
      }
    }
  }, [account, chainId, platform, startTime])

  const cdRef: any = useRef(null)
  useEffect(() => {
    const updateCountDown = () => {
      if (startTime) {
        const leftTime = (startTime - Date.now()) / 1000
        setWhitelistEndTime(leftTime)
        if (leftTime > 0) {
          const t = formatTime(leftTime)
          setLeftTime(t)
        } else {
          setLeftTime('')
          clearInterval(cdRef.current)
        }
      } else {
        clearInterval(cdRef.current)
      }
    }

    cdRef.current = setInterval(updateCountDown, 1000)

    return () => {
      clearInterval(cdRef.current)
      cdRef.current = null
    }
  }, [startTime])

  useEffect(() => {
    if (whitelistEndTime < 0) {
      clearInterval(cdRef.current)
      cdRef.current = null
    }
  }, [whitelistEndTime])

  const { onIdo } = idoLaunch(baseAsset.symbol.toLowerCase(), type)
  const handleIdo = useCallback(async () => {
    setRequestedIdo(true)
    try {
      const res = await onIdo(account, value)
      console.log('------res-----', res)
      setRequestedIdo(false)
    } catch (e) {
      console.log('handleIdo', e)
      setRequestedIdo(false)
    }
  }, [account, onIdo, value])

  return (
    <div>
      <CheckWL value={recipient} platform={platform} onChange={onChangeRecipient}></CheckWL>
      <Exchange>
        <ExchangePets
          options={options}
          baseAsset={baseAsset}
          updateBaseAsset={updateBaseAsset}
          balance={balance}
          value={value}
          updateValue={updateValue}
          petShow={false}
          idoMax={true}
          idoMin={true}
          // type={type}
        />
        {!account ? (
          <BtnConfirmed onClick={toggleWalletModal}>Connect Wallet</BtnConfirmed>
        ) : baseAsset.symbol.toLowerCase() === 'bnb' || (allowanceLaunch && +allowanceLaunch >= +value) ? (
          <BtnConfirmed
            onClick={handleIdo}
            disabled={!(value && +value > 0) || balance.lt(value) || requestedIdo || isLess() || checkIsEnd}
            style={startTime && whitelistEndTime >= 0 ? { cursor: 'auto', opacity: 0.4 } : {}}
          >
            {startTime && whitelistEndTime >= 0 ? (
              <>Countdown {leftTime}</>
            ) : checkIsEnd ? (
              'Finished'
            ) : requestedIdo ? (
              <AutoRow gap="6px" justify="center">
                Pending <Loader stroke="white" />
              </AutoRow>
            ) : balance.lt(value) ? (
              'Insufficient Balance'
            ) : (
              'Participate'
            )}
          </BtnConfirmed>
        ) : (
          <BtnConfirmed
            onClick={() => handleApproveLaunch()}
            disabled={requestedApprovalLaunch || checkIsEnd || balance.toNumber() <= 0}
          >
            {requestedApprovalLaunch ? (
              <AutoRow gap="6px" justify="center">
                Approving <Loader stroke="white" />
              </AutoRow>
            ) : (
              'Approve'
            )}
          </BtnConfirmed>
        )}
        {/* <Summary>
          <span>{`Bridging $1CAT can earn significant points! Note that the $1CAT bridged will be locked until the 1CAT Chain Mainnet launching at the end of March.`}</span>
        </Summary> */}
      </Exchange>
      {/* <Points pointInfo={pointInfo} /> */}
      {/* {![IDOTYPE.BENDDAO].includes(type) && (
      )} */}

      {/* <InvitationCode value={recipient} onChange={onChangeRecipient}></InvitationCode> */}
      <Des color={theme.bsText2}>{dataInfo.des}</Des>
      {dataInfo.illustration && (
        <img style={{ width: '100%', height: 'auto', marginTop: '20px' }} src={dataInfo.illustration} alt="" />
      )}
    </div>
  )
}
