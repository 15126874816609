import styled from 'styled-components'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTokenBalance } from 'state/wallet/index'
import { useActiveWeb3React } from 'hooks/index'
import { useETHBalances } from 'state/wallet/hooks'
import { useV2TradeExactIn, useV2TradeExactOut } from 'hooks/useV2Trade'
import { useAllTokens } from 'hooks/Tokens'
import { filterTokens } from 'components/SearchModal/filtering'
import { parseUnits } from '@ethersproject/units'
import JSBI from 'jsbi'
import { Token, Currency, CurrencyAmount, ETHER } from '@uniswap/sdk-core'
import { checkNftOnsell } from 'service/nftswap'
import { AutoRow } from 'components/Row'
import Loader from 'components/Loader'
import { useAddPopup } from 'state/application/hooks'
import idoLaunch from 'hooks/ido/idoLaunch'
import useAllowanceLaunch from 'hooks/ido/useAllowanceLaunch'
import useApproveLaunch from 'hooks/ido/useApproveLaunch'
import { useWalletModalToggle } from 'state/application/hooks'
import { ReactComponent as ETHIcon } from 'assets/svg/eth-logo.svg'
import { BigNumber } from 'bignumber.js'
import { ChainId } from '@uniswap/sdk-core'

const Wrapper = styled.div`
  position: relative;
  grid-row-gap: 4px;
  margin: 25px auto 0;
  width: 346px;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({ theme }) => theme.mediaWidth.upToBigerLarge`
    width: 315px;
    margin-top: 23px;
  `};
  ${({ theme }) => theme.mediaWidth.upToInfoLarge`
    width: 277px;
    margin-top: 20px;
  `};
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    margin-top: 28px;
    width: 100%;
    grid-row-gap: 3px;
  `}
`

const Block = styled.div`
  width: 100%;
  height: 86px;
  border-radius: 6px;
  background: ${({ theme }) => (theme.darkMode ? 'rgba(29, 30, 34, 0.7)' : '#FFF9F0')};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  ${({ theme }) => theme.mediaWidth.upToBigerLarge`
    height: 78px;
  `};
  ${({ theme }) => theme.mediaWidth.upToInfoLarge`
    height: 69px;
  `};
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    height: 68px;
    border-radius: 4px;
    padding: 0 14px;
  `}
`

const NumberWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

// const Unit = styled.div`
//   display: flex;
//   align-items: center;
//   font-family: Poppins, 'Inter var', sans-serif;
//   color: ${({ theme }) => theme.darkMode ? '#fff' : '#000'};
//   font-size: 14px;
//   font-weight: 500;
//   margin-top: 3px;
//   span {
//     color: #999;
//     font-weight: 400;
//   }
//   ${({ theme }) => theme.mediaWidth.upToExtraSmall`
//     font-size: 12px;
//     svg {
//       width: 11px;
//       height: 11px;
//     }
//   `}
// `

const CoinWrapper = styled(NumberWrapper)`
  align-items: flex-end;
`

const Number = styled.div`
  color: ${({ theme }) => (theme.darkMode ? '#fff' : '#722F0D')};
  font-family: Baloo Da, 'Inter var', sans-serif;
  font-size: 24px;
  font-weight: 700;
  ${({ theme }) => theme.mediaWidth.upToBigerLarge`
    font-size: 20px;
  `};
  ${({ theme }) => theme.mediaWidth.upToInfoLarge`
    font-size: 18px;
  `};
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    font-size: 22px;
  `}
`

const CoinType = styled.div`
  width: 104px;
  height: 27px;
  margin-bottom: 5px;
  border-radius: 10px;
  background: #ac562a;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => (theme.darkMode ? '#fff' : '#fff')};
  font-family: 'Roboto-Bold', 'Inter var', sans-serif;
  font-size: 13px;
  position: relative;
  .eth {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }
  ${({ theme }) => theme.mediaWidth.upToBigerLarge`
    font-size: 12px;
    width: 100px;
    .eth {
      width: 18px;
      height: 18px;
      margin-right: 7px;
    }
    .arrow {
      margin-left: 8px;
    }
  `};
  ${({ theme }) => theme.mediaWidth.upToInfoLarge`
    width: 94px;
    .eth {
      width: 16px;
      height: 16px;
      margin-right: 4px;
    }
    .arrow {
      margin-left: 6px;
    }
  `};
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    width: 104px;
    height: 22px;
    margin-bottom: 9px;
    border-radius: 19px;
    .eth {
      margin-right: 7px;
    }
  `}
`

const WhiteBg = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  margin-right: 8px;
  img {
    width: 100%;
    height: 100%;
  }
  ${({ theme }) => theme.mediaWidth.upToBigerLarge`
    width: 18px;
    height: 18px;
  `};
  ${({ theme }) => theme.mediaWidth.upToInfoLarge`
    width: 16px;
    height: 16px;
  `};
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    margin-right: 7px;
  `}
`

const Balance = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #b89684;
  font-family: Roboto, 'Inter var', sans-serif;
  font-size: 14px;
  span:first-child {
    color: ${({ theme }) => (theme.darkMode ? '#fff' : '#AC552A')};
  }
  ${({ theme }) => theme.mediaWidth.upToBigerLarge`
    font-size: 13px;
  `};
  ${({ theme }) => theme.mediaWidth.upToInfoLarge`
    font-size: 12px;
  `};
`

const TransArrow = styled.div`
  position: absolute;
  top: 72px;
  left: 50%;
  margin-left: -16px;
  width: 32px;
  height: 32px;
  border-radius: 4px;
  border: 2px solid ${({ theme }) => (theme.darkMode ? 'rgba(255, 255, 255, 0.1)' : '#FFF')};
  background: ${({ theme }) => (theme.darkMode ? '#2D2E32' : '#FFF9F0')};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  ${({ theme }) => theme.mediaWidth.upToBigerLarge`
    width: 29px;
    height: 29px;
    top: 64px;
    margin-left: -15px;
    svg {
      width: 20px;
      height: 20px;
    }
  `};
  ${({ theme }) => theme.mediaWidth.upToInfoLarge`
    width: 26px;
    height: 26px;
    top: 58px;
    margin-left: -13px;
    svg {
      width: 18px;
      height: 18px;
    }
  `};
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    top: 57px;
    margin-left: -13px;
    border-width: 1px;
  `}
`

const Button = styled.button`
  width: 346px;
  height: 50px;
  border-radius: 7px;
  background: #ac562a;
  color: #fff;
  font-family: Baloo Da, 'Inter var', sans-serif;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 22px auto 31px;
  cursor: pointer;
  padding: 0;
  outline: none;
  border: none;
  &:disabled {
    background-color: rgba(172, 86, 42, 0.2);
    color: #fffdfa;
    cursor: auto;
  }
  ${({ theme }) => theme.mediaWidth.upToBigerLarge`
    width: 315px;
    height: 45px;
    font-size: 15px;
    margin: 20px auto 28px;
  `};
  ${({ theme }) => theme.mediaWidth.upToInfoLarge`
    font-size: 13px;
    height: 40px;
    width: 277px;
    margin: 18px auto 25px;
  `};
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    width: 100%;
    height: 46px;
    font-size: 16px;
    margin: 24px auto 32px;
  `}
`

const TokenWrapper = styled.div`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0px;
  top: 27px;
  width: 128px;
  height: 113px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 0px 10px 0px rgba(66, 142, 241, 0.1);
  grid-row-gap: 22px;
  padding: 0 13px;
  display: none;
  z-index: 2;
  &.active {
    display: flex;
  }
`

const TokenText = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  color: #722f0d;
  font-family: 'Roboto-Medium', 'Inter var', sans-serif;
  font-size: 14px;
  svg,
  img.eth,
  svg.eth {
    margin-right: 18px;
    width: 20px;
    height: 20px;
  }
  ${({ theme }) => theme.mediaWidth.upToBigerLarge`
    svg,
    img.eth,
    svg.eth {
      margin-right: 16px;
    }
  `};
  ${({ theme }) => theme.mediaWidth.upToInfoLarge`
    svg, 
  img.eth,svg.eth {
      margin-right: 14px;
    }
  `};
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    img.eth,
    svg.eth {
      width: 20px;
      height: 20px;
    }
  `}
`

interface INFT {
  id: string
  nftId: string
}

const tabOptions: any = {
  eth: {
    symbol: 'ETH',
    title: 'ETH',
    type: 'DOGGYNFT',
  },
  doggy: {
    symbol: 'Doggy',
    title: 'bDoggyNFT',
    type: 'DOGGYMINTNFT',
  },
}

export default function TransBlock({
  isBuy,
  isDark,
  cart,
  number,
  swapOperate,
  transCallback,
}: {
  isBuy: boolean
  isDark: boolean
  cart: INFT[]
  number: number
  swapOperate: () => void
  transCallback: (isBuy: boolean) => void
}) {
  const { account, chainId } = useActiveWeb3React()
  const [selectItem, setSelectItem] = useState(tabOptions['eth'])
  const ethBalance = useETHBalances(account ? [account] : [])?.[account ?? ''] as CurrencyAmount<Currency>
  const doggyTokenBalance = useTokenBalance('doggy')
  const doggyBalance = useTokenBalance('doggyNFT')
  // const doggyBalance = new BigNumber(-1)
  const [ethPrice, setEthPrice] = useState('0')
  const [ethSwapPrice, setEthSwapPrice] = useState('0')
  const addPopup = useAddPopup()
  const [parseAmount, setParseAmount] = useState<CurrencyAmount<Token>>()
  const [calcLoading, setCalcLoading] = useState(false)
  const toggleWalletModal = useWalletModalToggle()
  const [isOpenToken, setIsOpenToken] = useState(false)

  const allTokens = useAllTokens()
  const doggyToken = useMemo(() => {
    return filterTokens(Object.values(allTokens), chainId === ChainId.GÖRLI ? 'DOGX' : 'bDoggyNFT')[0]
  }, [allTokens, chainId])

  useEffect(() => {
    if (!doggyToken || !number) return
    setCalcLoading(true)
    const typedValueParsed = parseUnits(`${number}`, doggyToken.decimals).toString()
    const parseAmount = CurrencyAmount.fromRawAmount(doggyToken, JSBI.BigInt(typedValueParsed))
    setParseAmount(parseAmount)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [number])

  let trade: CurrencyAmount<Currency> | undefined = undefined
  if (isBuy) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    trade = useV2TradeExactOut(ETHER, parseAmount)?.inputAmount
  } else {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    trade = useV2TradeExactIn(parseAmount, ETHER)?.outputAmount
  }

  useEffect(() => {
    if (!number) {
      setEthPrice('0')
      setEthSwapPrice('0')
      return
    }
    setEthPrice(trade?.toSignificant(4) || '0')
    setEthSwapPrice(trade?.toSignificant(18) || '0')

    setCalcLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trade])

  let allowanceType = 'DOGGYNFT'
  if (isBuy) {
    if (selectItem.symbol.toLowerCase() === 'doggy') {
      allowanceType = 'MINTDOGGY'
    }
  } else {
    if (selectItem.symbol.toLowerCase() === 'doggy') {
      allowanceType = 'DOGGYMINTNFT'
    }
  }
  const allowanceLaunch = useAllowanceLaunch(
    isBuy ? selectItem.symbol.toLowerCase() : 'doggynft',
    allowanceType
  )?.toNumber()
  const allowanceLaunchNum =
    isBuy && selectItem.symbol.toLowerCase() === 'doggy'
      ? new BigNumber(allowanceLaunch).div(new BigNumber(10).pow(18))?.toNumber()
      : allowanceLaunch

  const [requestedApprovalLaunch, setRequestedApprovalLaunch] = useState(false)
  const onApproveLaunch = useApproveLaunch(isBuy ? selectItem.symbol.toLowerCase() : 'doggynft', allowanceType)
  const handleApproveLaunch = useCallback(async () => {
    try {
      setRequestedApprovalLaunch(true)
      const txHash = await onApproveLaunch()
      if (!txHash) {
        setRequestedApprovalLaunch(false)
      } else {
        setTimeout(() => {
          setRequestedApprovalLaunch(false)
        }, 1000)
      }
    } catch (e) {
      console.log(e)
      setRequestedApprovalLaunch(false)
    }
  }, [onApproveLaunch, setRequestedApprovalLaunch])

  // buy
  const [requestedIdo, setRequestedIdo] = useState(false)
  const { onIdo } = idoLaunch('doggynft', selectItem.type)
  const handleTrans = useCallback(async () => {
    setRequestedIdo(true)
    try {
      if (isBuy) {
        const { data: res } = await checkNftOnsell(chainId as number, { nftIds: cart.map((item) => item.nftId) })
        if (res.code === 0) {
          const notSell = res?.data?.filter((item: any) => !item?.onSell)?.map((item: any) => item.nftId)
          if (notSell?.length > 0) {
            addPopup({
              txn: {
                hash: '',
                success: false,
                summary: `${'Sorry, Doggy ' + notSell.join(',') + ' is not available'}`,
              },
            })
            setRequestedIdo(false)
            return
          }
        } else {
          addPopup({
            txn: {
              hash: '',
              success: false,
              summary: res?.message,
            },
          })
          setRequestedIdo(false)
          return
        }
      }
      const data = await onIdo(
        account,
        ['DOGGYNFT'].includes(selectItem.type) ? ethSwapPrice : number,
        cart.map((item) => item.nftId),
        isBuy ? 'buy' : 'sell'
      )
      setTimeout(() => {
        if (data.status) {
          transCallback(isBuy)
        }
        setRequestedIdo(false)
      }, 1000)
    } catch (e) {
      console.log('handleIdo', e)
      setRequestedIdo(false)
    }
  }, [isBuy, onIdo, account, selectItem.type, ethSwapPrice, number, cart, chainId, addPopup, transCallback])

  const handleChooseToken = useCallback(
    (token) => {
      if (token === 'doggy' && calcLoading) {
        setCalcLoading(false)
      } else if (token === 'eth' && ethPrice === '0' && number) {
        setCalcLoading(true)
      }
      setSelectItem(tabOptions[token])
      setIsOpenToken(false)
    },
    [calcLoading, ethPrice, number]
  )

  const containerEl: any = useRef(null)
  const checkForOuterAction = (event: any) => {
    if (!containerEl.current) return

    const isOuterAction = !containerEl.current.contains(event.target) && event.target.innerText !== 'ETH'

    if (isOuterAction) {
      setIsOpenToken(false)
    }
  }

  useEffect(() => {
    window.addEventListener('mousedown', checkForOuterAction)
    return () => {
      window.removeEventListener('mousedown', checkForOuterAction)
    }
  }, [])

  return (
    <>
      <Wrapper style={{ flexDirection: !isBuy ? 'column-reverse' : 'column' }}>
        <Block>
          <NumberWrapper>
            <Number>
              {calcLoading ? <Loader stroke="black" /> : selectItem?.symbol.toLowerCase() === 'eth' ? ethPrice : number}
            </Number>
          </NumberWrapper>
          <CoinWrapper>
            <CoinType style={{ cursor: 'pointer' }} onClick={() => setIsOpenToken(true)}>
              {selectItem?.symbol?.toLowerCase() === 'eth' ? (
                <ETHIcon className="eth" style={{ width: 16, height: 16 }} />
              ) : (
                <img className="eth" src="/images/coins/i_doggy.png" style={{ width: 16, height: 16 }} />
              )}
              {selectItem?.title}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="8"
                height="8"
                viewBox="0 0 8 8"
                fill="none"
                style={{ marginLeft: selectItem?.symbol?.toLowerCase() === 'eth' ? 9 : 4 }}
              >
                <path
                  d="M0.220466 1.96546C-0.0331241 2.21195 -0.0331241 2.61195 0.220466 2.85908L3.49031 6.03574C3.74437 6.28211 4.15623 6.28211 4.40977 6.03574L7.67952 2.85908C7.93353 2.61203 7.93353 2.212 7.67952 1.96546C7.42585 1.71854 7.01407 1.71854 6.75998 1.96546L3.95002 4.69547L1.1404 1.96546C0.886374 1.71854 0.474562 1.71854 0.220427 1.96546H0.220466Z"
                  fill="#EED9CC"
                />
              </svg>
              <TokenWrapper ref={containerEl} className={isOpenToken ? 'active' : ''}>
                <TokenText
                  onClick={(event: { stopPropagation: () => void }) => {
                    event.stopPropagation()
                    handleChooseToken('doggy')
                  }}
                >
                  <img
                    src="/images/coins/i_doggy.png"
                    className="eth"
                    style={{ borderRadius: '50%', width: 20, height: 20 }}
                  />
                  bDoggyNFT
                </TokenText>
                <TokenText
                  onClick={(event) => {
                    event.stopPropagation()
                    handleChooseToken('eth')
                  }}
                >
                  <ETHIcon className="eth" />
                  ETH
                </TokenText>
              </TokenWrapper>
            </CoinType>
            <Balance>
              Balance:&nbsp;
              <span>
                {selectItem?.symbol.toLowerCase() === 'eth'
                  ? ethBalance?.toSignificant(4)
                  : account
                  ? doggyTokenBalance?.toFixed(2, BigNumber.ROUND_DOWN).toString()
                  : ''}
              </span>
            </Balance>
          </CoinWrapper>
        </Block>
        <Block>
          <NumberWrapper>
            <Number>{number}</Number>
          </NumberWrapper>
          <CoinWrapper>
            <CoinType>
              <WhiteBg>
                <img src="/images/nftswap/website.png" />
              </WhiteBg>
              DoggyNFT
            </CoinType>
            <Balance>
              Balance:&nbsp;<span>{doggyBalance.toNumber() === -1 ? 0 : doggyBalance.toNumber()}</span>&nbsp;
            </Balance>
          </CoinWrapper>
        </Block>
        <TransArrow onClick={() => swapOperate()}>
          <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
            <path
              d="M9.57595 15.3976L6.59288 12.3983C6.40998 12.2144 6.16175 12.1109 5.90274 12.1109C5.64373 12.1109 5.3955 12.2144 5.2126 12.3983C5.02974 12.5821 4.92715 12.8313 4.92715 13.091C4.92715 13.2196 4.95234 13.347 5.0013 13.4658C5.05026 13.5847 5.12205 13.6927 5.2126 13.7838L5.21261 13.7838L9.88203 18.4783L9.88204 18.4783C10.057 18.6542 10.2922 18.7568 10.5398 18.765C10.7869 18.7732 11.0277 18.6868 11.2137 18.5236C11.2345 18.5061 11.2539 18.488 11.2726 18.4692L15.9416 13.7747C15.9416 13.7747 15.9416 13.7746 15.9416 13.7746C16.1245 13.5908 16.2271 13.3417 16.2271 13.0821C16.2272 12.8224 16.1247 12.5732 15.9419 12.3894C15.759 12.2055 15.5108 12.102 15.2519 12.1019C14.9929 12.1019 14.7447 12.2053 14.5618 12.3891L14.5618 12.3891L11.5268 15.4407V4.2147C11.5268 3.91514 11.4522 3.66287 11.2768 3.48647C11.1012 3.30992 10.8498 3.23477 10.5514 3.23477C10.2529 3.23477 10.0015 3.30992 9.82596 3.48647C9.65051 3.66287 9.57595 3.91514 9.57595 4.2147V15.3976Z"
              fill={isDark ? '#fff' : '#AC562A'}
              stroke={isDark ? '#fff' : '#AC562A'}
              strokeWidth="0.3"
            />
          </svg>
        </TransArrow>
      </Wrapper>
      {!account ? (
        <Button onClick={toggleWalletModal}>Connect Wallet</Button>
      ) : (!allowanceLaunch && !isBuy) ||
        (!allowanceLaunch && !isBuy) ||
        (isBuy &&
          ['DOGGYMINTNFT'].includes(selectItem.type) &&
          (!allowanceLaunch || (allowanceLaunch && new BigNumber(allowanceLaunchNum).lt(number?.toString())))) ? (
        <Button disabled={requestedApprovalLaunch} onClick={handleApproveLaunch}>
          {requestedApprovalLaunch ? (
            <AutoRow gap="6px" justify="center">
              Approving <Loader stroke="white" />
            </AutoRow>
          ) : isBuy && selectItem?.symbol.toLowerCase() === 'doggy' ? (
            'Approve Doggy'
          ) : (
            'Approve DoggyNFT'
          )}
        </Button>
      ) : (
        <Button
          disabled={
            requestedIdo ||
            calcLoading ||
            !number ||
            (isBuy &&
              ((selectItem?.symbol.toLowerCase() === 'eth' && ethBalance && trade && !trade?.lessThan(ethBalance)) ||
                (selectItem?.symbol.toLowerCase() === 'doggy' && doggyTokenBalance.lt(number)))) ||
            (!isBuy && number > doggyBalance.toNumber())
          }
          onClick={() => handleTrans()}
        >
          {requestedIdo ? (
            <AutoRow gap="6px" justify="center">
              Pending <Loader stroke="white" />
            </AutoRow>
          ) : !number ? (
            'Choose NFTs'
          ) : isBuy ? (
            ethBalance && trade && !trade?.lessThan(ethBalance) ? (
              'Insufficient ETH Balance'
            ) : (
              'Buy'
            )
          ) : number > doggyBalance.toNumber() ? (
            'Insufficient DoggyNFT Balance'
          ) : (
            'Sell'
          )}
        </Button>
      )}
    </>
  )
}
