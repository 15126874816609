import { createReducer } from '@reduxjs/toolkit'
import { updateUserInfo } from './actions'

export interface TwitterState {
  userInfo: any
}

export const initialState: TwitterState = {
  userInfo: null,
}

export default createReducer(initialState, (builder) =>
  builder.addCase(updateUserInfo, (state, action) => {
    state.userInfo = action.payload.userInfo
  })
)
