import React, { useMemo } from 'react'
import styled, {
  ThemeProvider as StyledComponentsThemeProvider,
  createGlobalStyle,
  css,
  DefaultTheme,
} from 'styled-components'
import { useIsDarkMode } from '../state/user/hooks'
import { Text, TextProps } from 'rebass'
import { Colors } from './styled'

export * from './components'

export const MEDIA_WIDTHS = {
  upToMostSmall: 320,
  upToExtraSmall: 500,
  upToSmall: 600,
  upToMedium: 960,
  upToLarge: 1280,
  upToInfoLarge: 1536,
  upToBigerLarge: 1746,
}

const mediaWidthTemplates: { [width in keyof typeof MEDIA_WIDTHS]: typeof css } = Object.keys(MEDIA_WIDTHS).reduce(
  (accumulator, size) => {
    ;(accumulator as any)[size] = (a: any, b: any, c: any) => css`
      @media (max-width: ${(MEDIA_WIDTHS as any)[size]}px) {
        ${css(a, b, c)}
      }
    `
    return accumulator
  },
  {}
) as any

const white = '#FFFFFF'
const black = '#212833'

export function colors(darkMode: boolean): Colors {
  return {
    // base
    white,
    black,

    // text
    text: darkMode ? '#fff' : '#121212',
    text1: darkMode ? '#FFCD84' : '#722F0D',
    text2: darkMode ? '#C3C5CB' : '#565A69',
    text3: darkMode ? '#6C7284' : '#888D9B',
    text4: darkMode ? '#565A69' : '#C3C5CB',
    text5: darkMode ? '#2C2F36' : '#EDEEF2',
    text6: darkMode ? '#fff' : '#090909',
    text7: darkMode ? '#754D38' : '#754D38',
    bsText1: darkMode ? '#722F0D' : '#FFF3E0',
    bsText2: darkMode ? '#754D38' : '#D6A485',
    bsText3: darkMode ? '#D6A485' : '#C58560',
    bsText4: darkMode ? '#FFF3E0' : '#722F0D',
    bsText5: darkMode ? '#885034' : '#D6A485',
    bsText6: darkMode ? '#6F391D' : '#FFF3E0',
    bsText7: darkMode ? '#D6A485' : '#AC562A',
    bsText8: darkMode ? '#CDAA7B' : '#FFF3E0',
    bsText9: darkMode ? '#AC562A' : '#FFCD84',
    bsText10: darkMode ? '#776D63' : '#D6A485',
    bsText11: darkMode ? '#EED9CC' : '#4F4F4F',
    bsText12: darkMode ? '#885034' : '#885034',
    bsText13: darkMode ? '#EB5757' : '#EB5757',
    bsText14: darkMode ? '#FFCD84' : '#722F0D',
    bsText15: darkMode ? '#C58765' : '#C58765',
    bsText16: darkMode ? '#E8CAB4' : '#E8CAB4',
    bsText17: darkMode ? '#C58561' : '#C58561',
    bsText18: darkMode ? '#DA4550' : '#DA4550',
    bsText19: darkMode ? '#FDFDFD' : '#4E375A',
    bsText20: darkMode ? '#FDFDFD' : '#754D38',
    bsText21: darkMode ? '#EB5757' : '#FFFFFF',
    bsText22: darkMode ? '#FFF3E0' : '#553220',
    bsText23: darkMode ? '#C58561' : '#57392A',
    bsText24: darkMode ? '#776D63' : '#776D63',
    bsText25: darkMode ? '#FFFCF8' : '#FFFCF8',
    bsText26: darkMode ? '#2D241F' : '#2D241F',
    bsText27: darkMode ? '#776D63' : '#D2C9BD',
    bsText28: darkMode ? '#FFF3E0' : '#553220',
    bsText29: darkMode ? '#C1B6A8' : '#887063',
    bsText30: darkMode ? '#C1B6A8' : '#906E5A',
    bsText31: darkMode ? '#FF7A00' : '#FF7A00',
    bsText32: darkMode ? '#C1B6A8' : '#322925',
    bsText33: darkMode ? '#776D63' : '#FFB86B',
    bsText34: darkMode ? '#FFF3E0' : '#000000',
    bsText35: darkMode ? '#C1B6A8' : '#776D63',
    bsText36: darkMode ? '#FFF3E0' : '#322925',
    bsText37: darkMode ? '#FFF3E0' : '#57392A',
    bsText38: darkMode ? '#EED9CC' : '#C58560',
    bsText39: darkMode ? '#FFF3E0' : '#FFFFFF',
    bsText40: darkMode ? '#FFA8EB' : '#4E375A',
    bsText41: darkMode ? '#FFF3E0' : '#322925',
    bsText42: darkMode ? '#C1B6A8' : '#706862',
    bsText43: darkMode ? '#FFF3E0' : '#553220',
    bsText44: darkMode ? '#FFD600' : '#FFD600',
    bsText45: darkMode ? '#FFFFFF' : '#101317',

    // backgrounds / greys
    bg1: darkMode ? '#322925' : '#FFFDFA',
    bg2: darkMode ? '#2C2F36' : '#F7F8FA',
    bg3: darkMode ? '#754D38' : '#EED9CC',
    bg4: darkMode ? '#565A69' : '#CED0D9',
    bg5: darkMode ? '#6C7284' : '#888D9B',
    bg6: darkMode ? '#000000' : '#FBF1E6',
    bg7: darkMode ? '#160F0B' : '#FFEDD1',
    bsBg1: darkMode ? '#1A110D' : '#FFF3E0',
    bsBg2: darkMode ? '#806A5A' : '#F0D2BC',
    bsBg3: darkMode ? '#5E4737' : '#D89E75',
    bsBg4: darkMode ? '#EFC990' : '#AC562A',
    bsBg5: darkMode ? '#302018' : '#FFFDFA',
    bsBg6: darkMode ? '#322925' : '#FFF7EB',
    bsBg7: darkMode ? '#443123' : '#C27A4F',
    bsBg8: darkMode ? '#FFCD84' : '#6F391D',
    bsBg9: darkMode ? '#885034' : '#461A03',
    bsBg10: darkMode ? '#322925' : '#FFF9F0',
    bsBg11: darkMode ? '#C58560' : '#C58560',
    bsBg12: darkMode ? '#D6A485' : '#D6A485',
    bsBg13: darkMode ? '#EED9CC' : '#EED9CC',
    bsBg14: darkMode ? '#D6A485' : '#F8E6DC',
    bsBg15: darkMode ? '#322925' : '#FFFCF6',
    bsBg16: darkMode ? '#F2C8AE' : '#F2C8AE',
    bsBg17: darkMode ? '#5B4E47' : '#5B4E47',
    bsBg18: darkMode ? '#EED9CC' : '#FFFFFF',
    bsBg19: darkMode ? '#4D4A44' : '#FAF0E5',
    bsBg20: darkMode ? '#FBE8D5' : '#FBE8D5',
    bsBg21: darkMode ? '#F7E3CE' : '#F7E3CE',
    bsBg22: darkMode ? '#EACEBA' : '#EACEBA',
    bsBg23: darkMode ? '#221712' : '#FFF3E0',
    bsBg24: darkMode ? '#38291D' : '#FFE8C5',
    bsBg25: darkMode ? '/images/homeNew/swap_bg_dark.png' : '/images/homeNew/swap_bg.png',
    bsBg26: darkMode ? '/images/homeNew/nft_bg_dark.png' : '/images/homeNew/nft_bg.png',
    bsBg27: darkMode ? '#322925' : '#FFFFFF',
    bsBg28: darkMode ? '#FFD8A0' : '#FFD8A0',
    bsBg29: darkMode ? '#33312D' : '#FFF3E0',
    bsBg30: darkMode ? '#1A110D' : '#FFFAF2',
    bsBg31: darkMode ? '#24211C' : '#FFFDFA',
    bsBg32: darkMode ? '#2B2924' : '#FFFBF5',
    bsBg33: darkMode ? '#33312D' : '#FFF8ED',
    bsBg34: darkMode
      ? 'linear-gradient(271.56deg, #85641C 44.99%, #854F1C 74.63%)'
      : 'linear-gradient(304.65deg, #FFDD95 7.84%, #FFC895 70.15%)',
    bsBg36: darkMode ? '#33312D' : '#FFFFFF',
    bsBg37: darkMode ? '#2B2924' : '#FFF9F0',
    bsBg38: darkMode ? '#4D4A44' : '#FFFFFF',
    bsBg39: darkMode ? 'rgba(93, 81, 63, 0.6)' : 'rgba(253, 242, 231, 0.6)',
    bsBg40: darkMode ? '#2B2924' : '#FFFFFF',
    bsBg41: darkMode ? '#2E2C29' : '#FFFFFF',
    bsBg42: darkMode ? '#4C4943' : '#FFFAF2',
    bsBg43: darkMode ? 'initial' : '#FFFFFF',
    bsBg44: darkMode ? 'initial' : 'linear-gradient(180deg, rgba(255, 255, 255, 0.6) 0%, #FFFFFF 100%)',
    bsBg45: darkMode ? '#754D38' : '#F6EEE9',

    // button
    btnBg1: darkMode ? '#5B4A38' : '#EED9CC',
    btnBg2: darkMode ? '#AE895D' : '#C27A4F',
    btnBg3: darkMode
      ? 'linear-gradient(305.04deg, #FFD600 -43.12%, #FF7A00 70.33%)'
      : 'linear-gradient(305.04deg, #FFD600 -43.12%, #FF7A00 70.33%)',

    // button color
    btnText1: darkMode ? '#E1CCAC' : '#FFFDFA',
    btnText2: darkMode ? '#905C35' : '#E1B697',

    // border
    bsBorder1: darkMode ? '#4E3B32' : '#ac552a',
    bsBorder2: darkMode ? '#5B4A38' : '#EED9CC',
    bsBorder3: darkMode ? '#FAF0E5' : '#FAF0E5',
    bsBorder4: darkMode ? '#E6DFD4' : '#E6DFD4',
    bsBorder5: darkMode ? '#FF7A00' : '#FFD600',
    bsBorder6: darkMode ? '#2B2924' : '#E6E2DC',
    bsBorder7: darkMode ? '#FFCD84' : '#FFCD84',
    bsBorder8: darkMode ? 'rgba(193,182,168, 0.3)' : 'rgba(193,182,168, 0.3)',
    bsBorder9: darkMode ? '#4D4A44' : '#E0DAD3',
    bsBorder10: darkMode ? '#5B4A38' : '#E6CCBF',

    //specialty colors
    modalBG: darkMode ? 'rgba(0,0,0,.425)' : 'rgba(0,0,0,0.3)',
    advancedBG: darkMode ? 'rgba(0,0,0,0.1)' : 'rgba(255,255,255,0.6)',

    //primary colors
    primary1: darkMode ? '#FFCD84' : '#AC562A',
    primary2: darkMode ? '#3680E7' : '#FF8CC3',
    primary3: darkMode ? '#4D8FEA' : '#FF99C9',
    primary4: darkMode ? '#FFCD8470' : '#AC562A70',
    primary5: darkMode ? '#FFCD84' : '#AC562A',

    // color text
    primaryText1: darkMode ? '#722F0D' : '#FFFDFA',

    // secondary colors
    secondary1: darkMode ? '#2172E5' : '#ff007a',
    secondary2: darkMode ? '#17000b26' : '#F6DDE8',
    secondary3: darkMode ? '#17000b26' : '#FDEAF1',

    boxShadow1: darkMode ? '0px 2px 18px rgba(171, 133, 115, 0.08)' : '0px 1.75625px 17.5625px rgba(0, 0, 0, 0.08)',

    // other
    red1: '#FF6871',
    red2: '#F82D3A',
    red3: '#D60000',
    green1: '#27AE60',
    yellow1: '#FFE270',
    yellow2: '#428EF1',
    yellow3: '#F3B71E',
    blue1: '#2172E5',
    blue2: '#5199FF',

    error: '#FD4040',
    success: '#27AE60',
    warning: '#ff8f00',
    themeColor: darkMode ? '#FF9900' : '#FF8718',
  }
}

export function theme(darkMode: boolean): DefaultTheme {
  return {
    ...colors(darkMode),

    grids: {
      sm: 8,
      md: 12,
      lg: 24,
    },

    //shadows
    shadow1: darkMode ? '#000' : '#2F80ED',

    // media queries
    mediaWidth: mediaWidthTemplates,

    // css snippets
    flexColumnNoWrap: css`
      display: flex;
      flex-flow: column nowrap;
    `,
    flexRowNoWrap: css`
      display: flex;
      flex-flow: row nowrap;
    `,

    darkMode: darkMode,
  }
}

export default function ThemeProvider({ children }: { children: React.ReactNode }) {
  const darkMode = useIsDarkMode()

  const themeObject = useMemo(() => theme(darkMode), [darkMode])

  return <StyledComponentsThemeProvider theme={themeObject}>{children}</StyledComponentsThemeProvider>
}

const TextWrapper = styled(Text)<{ color: keyof Colors }>`
  color: ${({ color, theme }) => (theme as any)[color]};
`

export const TYPE = {
  main(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'text2'} {...props} />
  },
  link(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'primary1'} {...props} />
  },
  label(props: TextProps) {
    return <TextWrapper fontWeight={600} color={'text1'} {...props} />
  },
  black(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'text1'} {...props} />
  },
  white(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'white'} {...props} />
  },
  body(props: TextProps) {
    return <TextWrapper fontWeight={400} fontSize={16} color={'text1'} {...props} />
  },
  small(props: TextProps) {
    return <TextWrapper fontWeight={500} fontSize={11} {...props} />
  },
  largeHeader(props: TextProps) {
    return <TextWrapper fontWeight={600} fontSize={24} {...props} />
  },
  mediumHeader(props: TextProps) {
    return <TextWrapper fontWeight={500} fontSize={20} {...props} />
  },
  subHeader(props: TextProps) {
    return <TextWrapper fontWeight={400} fontSize={14} {...props} />
  },
  blue(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'primary1'} {...props} />
  },
  yellow(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'yellow1'} {...props} />
  },
  darkGray(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'text3'} {...props} />
  },
  gray(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'bg3'} {...props} />
  },
  italic(props: TextProps) {
    return <TextWrapper fontWeight={500} fontSize={12} fontStyle={'italic'} color={'text2'} {...props} />
  },
  error({ error, ...props }: { error: boolean } & TextProps) {
    return <TextWrapper fontWeight={500} color={error ? 'red1' : 'text2'} {...props} />
  },
}

export const FixedGlobalStyle = createGlobalStyle`
html, input, textarea, button {
  font-family: 'Inter', sans-serif;
  letter-spacing: -0.018em;
  font-display: fallback;
}
@supports (font-variation-settings: normal) {
  html, input, textarea, button {
    font-family: 'Inter var', sans-serif;
  }
}

html,
body {
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
}

button {
  user-select: none;
}

html {
  font-size: 16px;
  font-variant: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
`

export const ThemedGlobalStyle = createGlobalStyle`
html {
  color: ${({ theme }) => theme.text1};
  background-color: ${({ theme }) => theme.bg2};
}

body {
  min-height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: ${({ theme }) => theme.bsBg30};
  background-image: ${({ theme }) => (theme.darkMode ? 'url(images/bg-dark.jpg)' : 'url(images/bg.jpg)')};
}
body.nft {
  background-image: url(images/nft-image-bg.png);
}
video::-webkit-media-controls-volume-control-container {
  display: none;
}
video::-webkit-media-controls-fullscreen-button {
  display: none;
}
video::-webkit-media-controls-time-remaining-display {
  font-family: Baloo Da, 'Inter var', sans-serif;
}
video::-webkit-media-controls-current-time-display {
  font-family: Baloo Da, 'Inter var', sans-serif;
}
`

export const DplayerGlobalStyle = createGlobalStyle`
.dplayer-controller {
  padding: 0!important;
  .dplayer-icons.dplayer-icons-right {
    right: 3px!important;
  }
}
.dplayer-volume, .dplayer-setting {
  width: 0!important;
  height: 0!important;
  overflow: hidden!important;
}
.dplayer-bar-wrap {
  margin-left: 10px;
  width: calc(100% - 20px)!important;
}
.dplayer-play-icon {
  display: inline-block!important;
}
.dplayer-played {
  background: #428EF1!important;
}
.dplayer-time {
  font-size: 14px!important;
}
.dplayer-thumb {
  background: #428EF1!important;
}
.dplayer-notice, .dplayer-mobile-play {
  display: none!important;
}
`

export const AplayerGlobalStyle = createGlobalStyle`
#audioplay {
  margin: 0!important;
  width: 100%;
  box-shadow: none!important;
  border-radius: 28px;
  background: none!important;
  .aplayer-body {
    position: absolute;
    bottom: 30px;
    padding: 0 12px;
    width: 100%;
    box-sizing: border-box;
  }
  .aplayer-pic {
    float: none;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 0;
  }
  .aplayer-info {
    margin-left: 0;
    padding: 0;
    width: 100%;
  }
  .aplayer-controller {
    flex-direction: column;
    .aplayer-bar-wrap {
      margin: 0;
    }
    .aplayer-bar {
      height: 3px;
      border-radius: 3px;
      background: rgba(255, 255, 255, 0.2);
    }
    .aplayer-loaded {
      height: 3px;
      background: rgba(255, 255, 255, 0.2);
    }
    .aplayer-played {
      height: 3px;
    }
    .aplayer-played, .aplayer-thumb, .aplayer-loading-icon svg path {
      background: #428EF1!important;
    }
    .aplayer-thumb {
      margin-top: -7px;
      margin-right: -13px;
      border: 3px solid rgba(255,205,132,0.16)!important;
      background-clip: padding-box!important;
    }
  }
  .aplayer-time {
    padding-left: 0;
    .aplayer-time-inner {
      display: flex;
      justify-content: space-between;
      font-size: 12px;
      font-family: Baloo Da, 'Inter var', sans-serif;
      color: rgba(255, 255, 255, 0);
      .aplayer-ptime, .aplayer-dtime {
        color: #FFFFFF;
        opacity: 0.7;
      }
    }
  }
  .aplayer-button {
    position: relative;
    margin-top: 30px;
    width: 49px;
    height: 49px;
    transition: none;
    bottom: 0!important;
    right: 0!important;
    border: 1px solid rgba(255, 255, 255, 0.4);
    svg {
      top: 15px;
      left: 15px;
      height: 20px;
      width: 20px;
    }
  }
  .aplayer-play {
    margin: 30px 0 0!important;
  }
  .aplayer-music, .aplayer-volume-wrap, .aplayer-icon-loop {
    display: none;
  }
}
`

export const ThemedBackground = styled.div<{ backgroundColor?: string | undefined }>`
  position: fixed;
  top: 0;
  left: calc(-100vw / 2);
  right: 0;
  pointer-events: none;
  /* max-width: 100vw !important; */
  width: 200vw;
  height: 200vh;
  mix-blend-mode: color;
  background: ${({ backgroundColor }) =>
    `radial-gradient(50% 50% at 50% 50%, ${
      backgroundColor ? backgroundColor : '#fc077d10'
    } 0%, rgba(255, 255, 255, 0) 100%)`};
  transform: translateY(-100vh);
  will-change: background;
  transition: background 450ms ease;
`
