import React, { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import { useActiveWeb3React } from '../hooks'
import { getIp } from '../service/ip'

const Wrapper = styled.div``

const Mask = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: rgba(17, 15, 13, 0.94);
  left: 0px;
  top: 0px;
`

const ContentLimit = styled.div`
  background: #fff3e0;
  border-radius: 20px;
  position: fixed;
  width: 855px;
  height: 466px;
  max-width: 90%;
  z-index: 10000;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  margin: auto;
  padding: 70px 85px;
  overflow: auto;
  @media screen and (max-width: 500px) {
    width: 313px;
    height: 404px;
    padding: 43px 25px 0px;
  }
`
const Image = styled.img`
  width: 118px;
  height: 118px;
  display: block;
  margin: auto;
  @media screen and (max-width: 500px) {
    width: 54px;
    height: 54px;
  }
`

const TitleLimit = styled.div`
  font-family: Roboto-Bold;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 39px;
  text-align: center;
  color: #754d38;
  margin-top: 25px;
  @media screen and (max-width: 500px) {
    font-size: 20px;
    width: 230px;
    margin: auto;
    margin-top: 15px;
  }
`

const TextLimit = styled.div`
  font-family: Roboto-Light;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  text-align: center;
  color: #ac562a;
  letter-spacing: 0em;
  text-align: center;
  margin-top: 24px;
  width: 100%;
  @media screen and (max-width: 500px) {
    font-size: 16px;
    width: 241px;
    margin: auto;
    margin-top: 24px;
  }
`

export default function LimitIp() {
  const { chainId } = useActiveWeb3React()
  const [isLimited, setIsLimited] = useState(false)

  const fetchLimitIp = useCallback(() => {
    getIp(chainId as number, {}, (err: any, data: any) => {
      if (err) return
      if (data.code !== 0) return
      const countryCode = data.data.countryCode
      const country = data.data.country
      if (
        window.location.hostname !== 'localhost' &&
        (countryCode === 'CN' || country === 'China' || countryCode === 'US' || country === 'United States')
      ) {
        setIsLimited(true)
      } else {
        setIsLimited(false)
      }
    })
  }, [chainId])

  useEffect(() => {
    fetchLimitIp()
  }, [fetchLimitIp])

  return isLimited ? (
    <Wrapper>
      <Mask />
      <ContentLimit>
        <Image src="/images/icon/message.png" />
        <TitleLimit>SERVICE NOT AVAILABLE IN YOUR REGION</TitleLimit>
        <TextLimit>
          Unfortunately, our website is no longer providing service for certain regions, and we detected that your IP is
          from unsupported regions. Thanks for your understanding.
        </TextLimit>
      </ContentLimit>
    </Wrapper>
  ) : null
}
