import React, { useCallback, useEffect, useRef, useState } from 'react'

import styled from 'styled-components'

import Logo from '../../assets/svg/logo.svg'
import LogoDark from '../../assets/svg/logo_white.svg'
import Fold from '../../assets/svg/fold.svg'
import Unfold from '../../assets/svg/unfold.svg'
import Wordmark from '../../assets/svg/wordmark.svg'
import WordmarkDark from '../../assets/svg/wordmark_white.svg'
import { useActiveWeb3React } from '../../hooks'
import { useDarkModeManager } from '../../state/user/hooks'
import Settings from '../Settings'
// import Menu from '../Menu'

import Row, { RowBetween } from '../Row'
import Web3Status from '../Web3Status'
import useToggledVersion from 'hooks/useToggledVersion'
import { useDerivedSwapInfo } from 'state/swap/hooks'
// import VersionSwitch from './VersionSwitch'

const HeaderFrame = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  background: ${({ theme }) => theme.bsBg36};
  box-shadow: 0px 2px 10px rgba(171, 133, 115, 0.16);
  position: relative;
  z-index: 100;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    // padding: 12px 0 0 0;
    width: calc(100%);
    position: relative;
  `};
`

const HeaderElement = styled.div`
  display: flex;
  align-items: center;
`
const HeaderElementLeft = styled(HeaderElement)`
  ${({ theme }) => theme.mediaWidth.upToMedium`
    // flex-direction: column;
    align-items: center;
    width: 100%;
  `};
`

const HeaderElementWrap = styled.div`
  display: flex;
  align-items: center;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    // margin-top: 0.5rem;
  `};
`

const Title = styled.a`
  display: flex;
  align-items: center;
  pointer-events: auto;
  margin-left: 40px;

  :hover {
    cursor: pointer;
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-left: 10px;
  `};
`

const TitleText = styled(Row)`
  width: fit-content;
  white-space: nowrap;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: none;
  `};
`

const AccountElement = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme, active }) => (!active ? theme.bg1 : theme.bg3)};
  border-radius: 10px;
  white-space: nowrap;
  width: 100%;

  :focus {
    border: 1px solid blue;
  }
`

const FoldIcon = styled.div`
  transition: transform 0.3s ease;
  cursor: pointer;
  margin-left: 12px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    img {
      // width: 4.5rem;
    }
  `};
`

const UniIcon = styled.div`
  transition: transform 0.3s ease;
  cursor: pointer;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    img {
      // width: 4.5rem;
    }
  `};
`

const HeaderControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    // flex-direction: column;
    align-items: center;
    align-self: center;
  `};
`
const HeaderRowBetween = styled(RowBetween)`
  ${({ theme }) => theme.mediaWidth.upToMedium`
    // flex-direction: column;
  `};
`

const NavWrapper = styled.div`
  position: relative;

  .link__hidden {
    opacity: 0;
    overflow: hidden;
    height: 0;
  }
`

const TestnetWrapper = styled.div`
  width: 145px;
  height: 48px;
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 28px;
  border: 1px solid #c8c9cb;
  font-size: 16px;
  font-weight: 500;
  color: #333;
  cursor: pointer;
  img {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }
  svg {
    margin-left: 16px;
  }
  ${({ theme }) => theme.mediaWidth.upToBigerLarge`
    height: 37px;
    font-size: 14px;
  `};
  ${({ theme }) => theme.mediaWidth.upToInfoLarge`
    height: 34px;
    font-size: 12px;
  `};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 54px;
    height: 32px;
    margin-right: 8px;
    border-radius: 10px;
    img {
      width: 20px;
      height: 20px;
    }
    svg {
      margin-left: 0;
    }
  `};
`

const NetworkCard = styled.div`
  font-family: 'Roboto-Medium', 'Inter var', sans-serif;
  color: ${({ theme }) => (theme.darkMode ? '#fff' : '#333')};
  font-size: 16px;
  font-weight: 500;
  ${({ theme }) => theme.mediaWidth.upToBigerLarge`
    font-size: 15px;
  `};
  ${({ theme }) => theme.mediaWidth.upToInfoLarge`
    font-size: 13px;
  `};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
  `};
`

const NetWorkTitle = styled.div`
  color: #333;
  font-size: 14px;
  font-weight: 500;
  opacity: 0.8;
  padding: 16px 0 16px 24px;
  margin-bottom: 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  font-family: 'Roboto-Medium', 'Inter var', sans-serif;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 16px 0 16px 16px;
  `};
`

const LinksWrapper = styled.div`
  position: absolute;
  top: 60px;
  right: -88px;
  background: #fffdfa;
  border-radius: 4px;
  width: 247px;
  height: 232px;
  display: flex;
  flex-direction: column;
  z-index: 0;
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.16);
  a {
    height: 24px;
    padding-left: 24px;
    margin-bottom: 32px;
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    top: 45px;
    right: -130px;
    width: 208px;
    height: 197px;
    a {
      height: 18px;
      padding-left: 16px;
      margin-bottom: 24px;
    }
  `};
`

const NavLink = styled.a`
  text-decoration: none;
  display: flex;
  align-items: center;
  grid-column-gap: 15px;
  img {
    width: 24px;
    height: 24px;
  }
  ${({ theme }) => theme.mediaWidth.upToBigerLarge`
    img {
      width: 18px;
      height: 18px;
    }
  `};
`

const NavText = styled.div`
  color: #333;
  font-size: 16px;
  font-weight: 500;
  font-family: 'Roboto-Medium', 'Inter var', sans-serif;
  display: flex;
  align-items: center;
  ${({ theme }) => theme.mediaWidth.upToBigerLarge`
    font-size: 14px;
  `};
`

const links = [
  {
    name: 'Ethereum',
    testHref: 'https://www.bakeryswap.io',
    prodHref: 'https://www.bakeryswap.org',
    icon: '/images/eth.png',
  },
  {
    name: 'BSC',
    allName: 'BNB Smart Chain',
    testHref: 'https://bsc.bakeryswap.io',
    prodHref: 'https://bsc.bakeryswap.org',
    icon: '/images/bsc.png',
  },
  {
    name: 'Base',
    testHref: 'https://base.bakeryswap.io',
    prodHref: 'https://base.bakeryswap.org',
    icon: '/images/base.png',
  },
]

const choose = links[0]
export default function Header({ triggerFold, isFold }: { triggerFold: (fold: boolean) => void; isFold: boolean }) {
  const { account } = useActiveWeb3React()
  // const userEthBalance = useETHBalances(account ? [account] : [], chainId)?.[account ?? '']
  const [isDark] = useDarkModeManager()
  const toggledVersion = useToggledVersion()

  const { allowedSlippage } = useDerivedSwapInfo(toggledVersion)

  const [isTest, setIsTest] = useState(false)
  const [isViewLink, setIsViewLink] = useState(false)

  useEffect(() => {
    const href = window.location.href
    if (href.includes('www.bakeryswap.io') || href.includes('localhost')) {
      setIsTest(true)
    }
  }, [])

  const handleSwitch = useCallback(() => {
    setIsViewLink(!isViewLink)
  }, [isViewLink])

  const containerContactEl: any = useRef(null)
  const checkForOuterAction = (event: any) => {
    if (!containerContactEl.current || !event.target.className.indexOf) return
    const isOuterAction =
      !containerContactEl.current.contains(event.target) &&
      (!event.target.className || event.target.className.indexOf('switch') < 0)
    if (isOuterAction) {
      setIsViewLink(false)
    }
  }

  useEffect(() => {
    window.addEventListener('mousedown', checkForOuterAction)
    return () => {
      window.removeEventListener('mousedown', checkForOuterAction)
    }
  }, [])

  return (
    <HeaderFrame id="header_id">
      <HeaderRowBetween style={{ alignItems: 'center' }} padding="0.5rem">
        <HeaderElementLeft>
          <FoldIcon>
            <img src={isFold ? Fold : Unfold} style={{ height: 28 }} onClick={triggerFold.bind(null, !isFold)} alt="" />
          </FoldIcon>
          <Title href=".">
            <UniIcon>
              <img src={isDark ? LogoDark : Logo} style={{ height: 32 }} alt="logo" />
            </UniIcon>
            <TitleText>
              <img
                style={{ marginLeft: '5px', marginTop: '0px', height: 20 }}
                src={isDark ? WordmarkDark : Wordmark}
                alt="logo"
              />
            </TitleText>
          </Title>
        </HeaderElementLeft>
        <HeaderControls>
          <HeaderElement>
            <NavWrapper>
              <TestnetWrapper className="switch" onClick={() => handleSwitch()}>
                <img src={choose.icon} className="switch" />
                <NetworkCard className="switch">{choose.name}</NetworkCard>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="9"
                  height="8"
                  viewBox="0 0 9 8"
                  fill="none"
                  className="switch"
                  style={{ transform: isViewLink ? 'rotate(180deg)' : '' }}
                >
                  <path
                    d="M3.01248 6.54442L0.264404 2.4223C-0.426112 1.38703 0.316368 0 1.5605 0L7.05665 0C8.30078 0 9.04326 1.38703 8.35274 2.4223L5.60467 6.54442C4.9881 7.46877 3.62905 7.46877 3.01248 6.54442Z"
                    fill="#666666"
                    fillOpacity="0.7"
                  />
                </svg>
              </TestnetWrapper>
              <LinksWrapper ref={containerContactEl} className={!isViewLink ? 'link__hidden' : ''}>
                <NetWorkTitle>Select a Network</NetWorkTitle>
                {links.map((d, index) => {
                  return (
                    <NavLink href={isTest ? d.testHref : d.prodHref} key={index}>
                      <img src={d.icon} />
                      <NavText>{d.allName ? d.allName : d.name}</NavText>
                    </NavLink>
                  )
                })}
              </LinksWrapper>
            </NavWrapper>
            <AccountElement active={!!account} style={{ pointerEvents: 'auto' }}>
              {/* {account && userEthBalance ? (
                <BalanceText style={{ flexShrink: 0 }} pl="0.75rem" pr="0.5rem" fontWeight={500}>
                  {userEthBalance?.toSignificant(4)} ETH
                </BalanceText>
              ) : null} */}
              <Web3Status />
            </AccountElement>
          </HeaderElement>
          <HeaderElementWrap>
            <Settings placeholderSlippage={allowedSlippage} />
          </HeaderElementWrap>
        </HeaderControls>
      </HeaderRowBetween>
    </HeaderFrame>
  )
}
