import { CONTRACT_ADDRESSES } from '../constants/api'
export const getApi = (chainId: number) => {
  let url
  if (window.location.host.indexOf('www.bakeryswap.org') >= 0) {
    // url = 'https://api.v1.bakeryswap.org'
    url = 'https://api.bakeryswap.org/api'
  } else {
    url = CONTRACT_ADDRESSES.commonApi[chainId] || 'https://api.bakeryswap.io/api'
  }
  return url
}

// 处理url
export const getUrl = (listUrl: string, chainId: number, params?: any) => {
  let url = getApi(chainId) + listUrl
  if (params) {
    const paramsArray: any = []
    // 拼接参数
    Object.keys(params).forEach((key) => {
      if (params[key] !== undefined) {
        paramsArray.push(key + '=' + params[key])
      }
    })
    if (url.search(/\?/) === -1) {
      url += '?' + paramsArray.join('&')
    } else {
      url += '&' + paramsArray.join('&')
    }
  }
  return url
}
