import { getUrl } from './api'
import { getRequest } from './appService'

const common_path = '/v1/nft/'

export const fetchNFTList = async (chainId: number, params: any) => {
  const url = getUrl(`${common_path}doggy/pool`, chainId, params)
  return await getRequest(url)
}

export const fetchOwnerNFTs = async (chainId: number, params: any) => {
  const url = getUrl(`${common_path}doggy`, chainId, params)
  return await getRequest(url)
}

// // check nft onsell
export const checkNftOnsell = async (chainId: number, params: any) => {
  const url = getUrl(`${common_path}doggy/onsell`, chainId, params)
  return await getRequest(url)
}

export const fetchAllVolume = async (chainId: number) => {
  const url = getUrl(`/v1/token/all/doggy/volume`, chainId)
  return await getRequest(url)
}
