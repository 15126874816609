import http from './http'
import { getApi } from './api'

/**
 *  GET
 */
export function getReq(url: string, cb: any) {
  http
    .get(url)
    .then((data: any) => cb(null, data.data))
    .catch((error: any) => cb(error))
}

/**
 *  POST
 */
export function postReq(url: string, params: any, cb: any) {
  http
    .post(url, params)
    .then((data: any) => cb(null, data.data))
    .catch((error: any) => cb(error))
}

// 获取文件预签名
export const getSignature = (chainId: number, params: any, cb: any) => {
  const url = getApi(chainId) + '/v1/upload/pre-signature'
  postReq(url, params, cb)
}

// 上传文件
export const uploadKycImg = (url: string, params: any, cb: any) => {
  postReq(url, params, cb)
}

export function getRequest(url: string) {
  return http.get(url)
}

export function postRequest(url: string, params: any) {
  return http.post(url, params)
}
