import { createReducer } from '@reduxjs/toolkit'
import { updateSolanaPublicKey, updateSolanaWallet } from './actions'

export interface InfoState {
  solanaPublicKey: string
  solanaWallet: string
}

export const initialState: InfoState = {
  solanaPublicKey: '',
  solanaWallet: '',
}

export default createReducer(initialState, (builder) =>
  builder
    .addCase(updateSolanaPublicKey, (state, action) => {
      state.solanaPublicKey = action.payload.solanaPublicKey
    })
    .addCase(updateSolanaWallet, (state, action) => {
      state.solanaWallet = action.payload.solanaWallet
    })
)
