import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import { ButtonConfirmed } from 'components/Button'
import { useAddPopup } from 'state/application/hooks'
import { getWhitelist } from 'service/launchpad'

import { useActiveWeb3React } from 'hooks'
import { AutoRow } from 'components/Row'
import Loader from 'components/Loader'
// import whiteList from './whitelist.json'

const Check = styled.div`
  font-family: 'Roboto-Regular', 'Inter var', sans-serif;
  margin: 25px 0 50px;
  /* padding-bottom: 42px; */
  /* border-bottom: 1px solid ${({ theme }) => theme.bsBg45}; */
  /* letter-spacing: 0.01em; */
  border-radius: 10px;
  background: #fefaef;
  padding: 16px 24px 18px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin: 30px 0 30px;
    padding-bottom: 30px;
  `}
`

const Title = styled.div`
  color: #ac552a;
  font-family: 'Roboto-Bold';
  font-size: 14px;
  line-height: 119%;
  letter-spacing: 0.14px;
  margin-bottom: 12px;
`
const InputWrapper = styled.div`
  display: flex;
  width: 100%;
  border-radius: 12px;
  overflow: hidden;
  border: 1px solid rgba(172, 85, 42, 0.3);
  background: #fff;
  height: 48px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  `}
`
const CheckBtn = styled(ButtonConfirmed)`
  width: 120px;
  height: 48px;
  flex-shrink: 0;
  font-size: 18px;
  border-radius: 12px;
  font-weight: 400;
  padding: 0;
  color: ${({ theme }) => theme.primaryText1};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 120px;
    height: 54px;
    font-size: 16px;
  `}
`
const Summary = styled.div`
  display: flex;
  margin: 12px 0 0;
  font-size: 12px;
  text-align: left;
  color: #ff5151;

  img {
    margin: 0 7px 0 0;
    width: 18px;
    height: 18px;
    vertical-align: middle;
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 12px;
  `}
`
const Input = styled.input<{ error?: boolean }>`
  outline: none;
  border: none;
  flex: 1 1 auto;
  flex-shrink: 1;
  padding: 0 20px;
  width: 100%;
  height: 100%;
  font-family: 'Roboto-Regular', 'Inter var', sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
  letter-spacing: -0.01em;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.bsText7};

  ::placeholder {
    color: ${({ theme }) => theme.bsText7};
    opacity: 0.3;
  }
  -webkit-appearance: textfield;

  ::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    height: 54px;
  `}
`

export default function SupportCard({
  value,
  platform = 'bitcoincats',
  onChange,
}: {
  value: string
  platform?: string
  onChange: (value: string) => void
}) {
  const { chainId } = useActiveWeb3React()
  // show popup on confirm
  const addPopup = useAddPopup()
  const error = Boolean(false)
  const handleInput = useCallback(
    (event) => {
      const input = event.target.value
      const withoutSpaces = input.replace(/\s+/g, '')
      onChange(withoutSpaces)
    },
    [onChange]
  )

  const [checkObj, setCheckObj] = useState({
    success: false,
    icon: require('assets/svg/whitelist_error.svg').default,
    summary: '',
  })
  const [checkLoading, setCheckLoading] = useState(false)
  const checkWhitelist = useCallback(async () => {
    if (!value || checkLoading) return
    setCheckLoading(true)
    const { data: res } = await getWhitelist(chainId as number, { address: value, platform: platform })
    setCheckLoading(false)
    if (res.code === 0) {
      setCheckObj({
        ...checkObj,
        success: true,
        summary: res.data.isWhitelist
          ? 'This address is on the Whitelist'
          : 'This address is not on any Whitelist, but you can still participate in the IDO',
      })
    } else {
      addPopup({
        txn: {
          hash: '',
          success: false,
          summary: res?.message,
        },
      })
    }
  }, [value, checkLoading, chainId, checkObj, addPopup, platform])

  return (
    <Check>
      <Title>Check your WhiteList</Title>
      <InputWrapper>
        <Input
          className="recipient-address-input"
          type="text"
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck="false"
          placeholder="Enter your address"
          error={error}
          pattern="^(0x[a-fA-F0-9]{40})$"
          onChange={handleInput}
          value={value}
        />
        <CheckBtn onClick={checkWhitelist} disabled={checkLoading}>
          {checkLoading ? (
            <AutoRow gap="4px" justify="center">
              Loading <Loader stroke="white" />
            </AutoRow>
          ) : (
            'Submit'
          )}
        </CheckBtn>
      </InputWrapper>
      <Summary>{'Note that you can still participate even in the cases that you are not on the WhiteList :)'}</Summary>
      {checkObj.success && (
        <Summary>
          <img src={checkObj.icon} alt="" /> <span>{checkObj.summary}</span>
        </Summary>
      )}
    </Check>
  )
}
