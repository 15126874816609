import type { AppMetadata, CoinbaseWalletSDK, ProviderInterface } from '@coinbase/wallet-sdk'
import CoinbaseWallet from '@coinbase/wallet-sdk'
import type { WatchAssetParameters } from './type'
import { AbstractConnector } from '@web3-react/abstract-connector'
import warning from 'tiny-warning'
import { ConnectorUpdate } from '@web3-react/types'

import { SendReturnResult, SendReturn, SendOld } from '../GateConnector/types'

export class NoEthereumProviderError extends Error {
  public constructor() {
    super()
    this.name = this.constructor.name
    this.message = 'No Ethereum provider was found on window.coinbase.'
  }
}

function parseSendReturn(sendReturn: SendReturnResult | SendReturn): any {
  return sendReturn.result ? sendReturn.result : sendReturn
}

function parseChainId(chainId: string | number) {
  return typeof chainId === 'number' ? chainId : Number.parseInt(chainId, chainId.startsWith('0x') ? 16 : 10)
}

export class CoinbaseWalletConnector extends AbstractConnector {
  /** {@inheritdoc Connector.provider} */
  public provider: ProviderInterface | undefined
  public options: AppMetadata

  /**
   * A `CoinbaseWalletSDK` instance.
   */
  public coinbaseWallet: CoinbaseWalletSDK | undefined

  constructor(options: AppMetadata) {
    super({ supportedChainIds: options.appChainIds })
    this.options = options
    this.handleChainChanged = this.handleChainChanged.bind(this)
    this.handleAccountsChanged = this.handleAccountsChanged.bind(this)
  }

  private handleChainChanged(chainId: string | number): void {
    this.emitUpdate({ chainId: parseChainId(chainId) })
  }

  private handleAccountsChanged(accounts: string[]): void {
    if (accounts.length === 0) {
      // handle this edge case by disconnecting
      this.emitDeactivate()
    } else {
      this.emitUpdate({ account: accounts[0] })
    }
  }

  /**
   * Initiates a connection.
   *
   * @param desiredChainIdOrChainParameters - If defined, indicates the desired chain to connect to. If the user is
   * already connected to this chain, no additional steps will be taken. Otherwise, the user will be prompted to switch
   * to the chain, if one of two conditions is met: either they already have it added, or the argument is of type
   * AddEthereumChainParameter, in which case the user will be prompted to add the chain with the specified parameters
   * first, before being prompted to switch.
   */
  public async activate(): Promise<ConnectorUpdate> {
    try {
      this.provider = await this.getProvider()
      if (!this.provider) throw new Error('No provider')

      this.provider.on('chainChanged', this.handleChainChanged)
      this.provider.on('accountsChanged', this.handleAccountsChanged)
      // Wallets may resolve eth_chainId and hang on eth_accounts pending user interaction, which may include changing
      // chains; they should be requested serially, with accounts first, so that the chainId can settle.
      const accounts = await this.provider.request<string[]>({ method: 'eth_requestAccounts' })
      return { provider: this.provider, ...(accounts.length ? { account: accounts[0] } : {}) }
    } catch (error) {
      throw error
    }
  }
  async getProvider() {
    let provider = undefined
    if (!this.provider) {
      this.coinbaseWallet = new CoinbaseWallet(this.options)
      provider = this.coinbaseWallet.makeWeb3Provider({ options: 'all' }) as ProviderInterface
    }
    console.log(provider, this.provider)
    return provider
  }

  async getChainId() {
    const chainId = await this.provider?.request({
      method: 'eth_chainId',
    })
    return Number(chainId)
  }

  public async getAccount(): Promise<null | string> {
    if (!this.provider) {
      throw new NoEthereumProviderError()
    }

    let account
    try {
      account = await this.provider
        .request({ method: 'eth_accounts' })
        .then((sendReturn) => parseSendReturn(sendReturn)[0])
    } catch {
      warning(false, 'eth_accounts was unsuccessful, falling back to enable')
    }

    if (!account) {
      account = parseSendReturn((this.provider.request as SendOld)({ method: 'eth_accounts' }))[0]
    }

    return account
  }

  /** {@inheritdoc Connector.deactivate} */
  public deactivate(): void {
    if (this.provider) {
      this.provider.removeListener('chainChanged', this.handleChainChanged)
      this.provider.removeListener('accountsChanged', this.handleAccountsChanged)
      this.provider.removeListener('disconnect', () => {
        this.emitDeactivate()
      })
    }

    this.provider?.disconnect()
  }

  public async watchAsset({
    address,
    symbol,
    decimals,
    image,
  }: Pick<WatchAssetParameters, 'address'> & Partial<Omit<WatchAssetParameters, 'address'>>): Promise<true> {
    if (!this.provider) throw new Error('No provider')

    return this.provider
      .request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20',
          options: {
            address, // The address that the token is at.
            symbol, // A ticker symbol or shorthand, up to 5 chars.
            decimals, // The number of decimals in the token
            image, // A string url of the token logo
          },
        },
      })
      .then((success) => {
        if (!success) throw new Error('Rejected')
        return true
      })
  }
}
