import { useCallback, useMemo } from 'react'
import { useActiveWeb3React } from '../'
import { getContract } from 'utils/bakery/erc20'
import { provider } from 'web3-core'
import {
  approve,
  approveNftAll,
  getBakeryContract,
  getDoggyContract,
  getDoggyNFTContract,
  getDoggyBakeBaseDropAddress,
  getToken2NftBaseAddress,
  getSwapNFTAddress,
  getOneCatDropAddress,
  getWethContract,
  getPunkxContract,
  getDoggyCoinContract,
  getDoggyCoinDropAddress,
  getBitLandDropAddress,
  getOneCatContract,
  getBitCatDropAddress,
  getBendDaoDropAddress,
  getCatNFTAddress,
  getOpenSkyAddress,
  getBitcoinVMAddress,
  getWBTCContract,
  getIQ50Address,
  getBuzzAIAddress,
} from 'sushi/utils'
import useYam from '../bakery/useYam'

import { IDOTYPE } from 'constants/ido'

const useApproveLaunch = (tokenName: string, type?: string) => {
  const { account, library } = useActiveWeb3React()
  const ethereum = library?.provider
  const sushi = useYam()

  let lpTokenAddress = getDoggyBakeBaseDropAddress(sushi)
  switch (type) {
    case IDOTYPE.MINTDOGGY:
      lpTokenAddress = getToken2NftBaseAddress(sushi)
      break
    case IDOTYPE.DOGGYNFT:
      lpTokenAddress = getSwapNFTAddress(sushi)
      break
    case IDOTYPE.DOGGYMINTNFT:
      lpTokenAddress = getToken2NftBaseAddress(sushi)
      break
    case IDOTYPE.ONECAT:
      lpTokenAddress = getOneCatDropAddress(sushi)
      break
    case IDOTYPE.DOGGYCOIN:
      lpTokenAddress = getDoggyCoinDropAddress(sushi)
      break
    case IDOTYPE.BITLAND:
      lpTokenAddress = getBitLandDropAddress(sushi)
      break
    case IDOTYPE.BITCAT:
      lpTokenAddress = getBitCatDropAddress(sushi)
      break
    case IDOTYPE.BENDDAO:
      lpTokenAddress = getBendDaoDropAddress(sushi)
      break
    case IDOTYPE.CATNFT:
      lpTokenAddress = getCatNFTAddress(sushi)
      break
    case IDOTYPE.OPENSKY:
      lpTokenAddress = getOpenSkyAddress(sushi)
      break
    case IDOTYPE.BITCOINVM:
      lpTokenAddress = getBitcoinVMAddress(sushi)
      break
    case IDOTYPE.IQ50:
      lpTokenAddress = getIQ50Address(sushi)
      break
    case IDOTYPE.BUZZAI:
      lpTokenAddress = getBuzzAIAddress(sushi)
  }
  const lpContract = useMemo(() => {
    return getContract(ethereum as provider, lpTokenAddress)
  }, [ethereum, lpTokenAddress])

  let contract = getBakeryContract(sushi)
  switch (tokenName) {
    case 'doggy':
      contract = getDoggyContract(sushi)
      break
    case 'doggynft':
      contract = getDoggyNFTContract(sushi)
      break
    case 'bakery':
      contract = getBakeryContract(sushi)
      break
    case 'weth':
      contract = getWethContract(sushi)
      break
    case 'doggycoin':
      contract = getDoggyCoinContract(sushi)
      break
    case 'punkx':
      contract = getPunkxContract(sushi)
      break
    case 'onecat':
      contract = getOneCatContract(sushi)
      break
    case 'wbtc':
      contract = getWBTCContract(sushi)
      break
  }

  const handleApprove = useCallback(async () => {
    try {
      const tx = [IDOTYPE.DOGGYNFT, IDOTYPE.DOGGYMINTNFT].includes(type as any)
        ? await approveNftAll(contract, lpContract, account)
        : await approve(contract, lpContract, account)
      return tx
    } catch (e) {
      console.log('useApproveLaunch-->>', e)
      return false
    }
  }, [account, lpContract, contract, type])

  return handleApprove
}

export default useApproveLaunch
