import { useCallback, useState } from 'react'
import { useActiveWeb3React } from 'hooks'
import { useUpdateUserInfo } from 'state/twitter/hooks'
import { localStore, isObject, StorageEmitter, getAccountToken, removeAccountToken } from 'utils/tools'
import { useAddPopup } from 'state/application/hooks'
import { getUserInfo, oauthTwitter, login } from 'service/user'

export function useGetUserInfo(): [boolean, () => void] {
  const [infoLoading, setInfoLoading] = useState(false)
  const { chainId, account } = useActiveWeb3React()
  const updateUserInfo = useUpdateUserInfo()
  const addPopup = useAddPopup()
  const getUserInfoFn = useCallback(async () => {
    setInfoLoading(true)
    try {
      const res = await getUserInfo(chainId as number, account as string)
      if (res.code === 0) {
        if (res.data) {
          updateUserInfo(res.data)
        }
        if (res.data.address !== account) {
          removeAccountToken(account as string)
        }
      } else {
        addPopup({
          txn: {
            hash: '',
            success: false,
            summary: res.message,
          },
        })
      }
    } catch (e) {
      console.log('Get userInfo error:', e)
    }
    setInfoLoading(false)
  }, [chainId, account, updateUserInfo, addPopup])

  return [infoLoading, getUserInfoFn]
}

export function useOauthTwitter(): () => void {
  const { chainId } = useActiveWeb3React()
  const addPopup = useAddPopup()

  return useCallback(async () => {
    try {
      const res = await oauthTwitter(chainId as number, encodeURIComponent(window.location.href))
      if (res.code === 0) {
        window.location.href = res.data.url
      } else {
        addPopup({
          txn: {
            hash: '',
            success: false,
            summary: res.message,
          },
        })
      }
    } catch (e) {
      console.log('Oauth Twitter error:', e)
    }
  }, [chainId, addPopup])
}

export function useLoginTwitter(): () => void {
  const [loginLoading, loginFn] = useLogin()
  const oauthTwitter = useOauthTwitter()

  return useCallback(async () => {
    const token = getAccountToken()
    if (!token) {
      if (loginLoading) return
      loginFn()
    } else {
      oauthTwitter()
    }
  }, [loginLoading, loginFn, oauthTwitter])
}

export function useLogin(): [boolean, () => void] {
  const [loginLoading, setLoginLoading] = useState(false)
  const { account, library, chainId } = useActiveWeb3React()
  const updateUserInfo = useUpdateUserInfo()

  const addPopup = useAddPopup()

  const loginFn = useCallback(async () => {
    setLoginLoading(true)
    try {
      localStore.set('Bakeryswap.account', account)

      const timestamp = Date.now()
      const key = localStore.get('Bakeryswap.provider')
      let params = []
      switch (key) {
        case 'WALLET_LINK':
          params = [`Welcome to Bakery!`, account]
          break
        case 'BINANCE':
          params = [`0x${Buffer.from('Welcome to Bakery!', 'utf8').toString('hex')}`, account]
          break
        default:
          params = [account, `Welcome to Bakery!`]
          break
      }

      const signature = await library?.send('personal_sign', params)

      const res: any = await login({
        chainId: chainId || 1,
        params: { timestamp, signature, address: account },
      })
      if (res.code === 0) {
        const data = res.data
        // after delete
        const token = localStore.get('Bakeryswap.token')
        localStore.set('Bakeryswap.token', {
          ...(isObject(token) ? token : {}),
          [account as string]: data.accessToken,
        })
        // const timestamp = localStore.get('valid.timestamp')
        // localStore.set('valid.timestamp', {
        //   ...(isObject(timestamp) ? timestamp : {}),
        //   [account as string]: new Date().getTime(),
        // })
        if (data.address && data.address === account) {
          updateUserInfo(data.userInfo)
        }
        StorageEmitter.emit('storageSetItem')
      } else {
        addPopup({
          txn: {
            hash: '',
            success: false,
            summary: res.msg,
          },
        })
      }
    } catch (e) {
      console.log('Login error:', e)
    }
    setLoginLoading(false)
  }, [account, library, chainId, updateUserInfo, addPopup])

  return [loginLoading, loginFn]
}
