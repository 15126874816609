import { Web3Provider } from '@ethersproject/providers'
import { ChainId } from '@uniswap/sdk-core'
import { useWeb3React as useWeb3ReactCore } from '@web3-react/core'
import { Web3ReactContextInterface } from '@web3-react/core/dist/types'
import { useEffect, useMemo, useRef, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { injected } from '../connectors'
import { NetworkContextName } from '../constants/misc'
import { Contract } from '@ethersproject/contracts'
import { getContract } from '../utils'
import { getWeb3 } from 'utils/web3'

export function useActiveWeb3React(): Web3ReactContextInterface<Web3Provider> & { chainId?: ChainId } {
  const context = useWeb3ReactCore<Web3Provider>()
  const contextNetwork = useWeb3ReactCore<Web3Provider>(NetworkContextName)
  let id = 1
  if (window.location.host.indexOf('www.bakeryswap.org') < 0) {
    id = 11155111
  }
  return context.active ? context : { ...contextNetwork, chainId: id }
}

export function useEagerConnect() {
  const { activate, active } = useWeb3ReactCore() // specifically using useWeb3ReactCore because of what this hook does
  const [tried, setTried] = useState(false)

  useEffect(() => {
    injected.isAuthorized().then((isAuthorized) => {
      if (isAuthorized) {
        activate(injected, undefined, true).catch(() => {
          setTried(true)
        })
      } else {
        if (isMobile && window.ethereum) {
          activate(injected, undefined, true).catch(() => {
            setTried(true)
          })
        } else {
          setTried(true)
        }
      }
    })
  }, [activate]) // intentionally only running on mount (make sure it's only mounted once :))

  // if the connection worked, wait until we get confirmation of that to flip the flag
  useEffect(() => {
    if (active) {
      setTried(true)
    }
  }, [active])

  return tried
}

/**
 * Use for network and injected - logs user in
 * and out after checking what network theyre on
 */
export function useInactiveListener(suppress = false) {
  const { active, error, activate } = useWeb3ReactCore() // specifically using useWeb3React because of what this hook does

  useEffect(() => {
    const { ethereum } = window

    if (ethereum && ethereum.on && !active && !error && !suppress) {
      const handleChainChanged = () => {
        // eat errors
        activate(injected, undefined, true).catch((error) => {
          console.error('Failed to activate after chain changed', error)
        })
      }

      const handleAccountsChanged = (accounts: string[]) => {
        if (accounts.length > 0) {
          // eat errors
          activate(injected, undefined, true).catch((error) => {
            console.error('Failed to activate after accounts changed', error)
          })
        }
      }

      ethereum.on('chainChanged', handleChainChanged)
      ethereum.on('accountsChanged', handleAccountsChanged)

      return () => {
        if (ethereum.removeListener) {
          ethereum.removeListener('chainChanged', handleChainChanged)
          ethereum.removeListener('accountsChanged', handleAccountsChanged)
        }
      }
    }
    return undefined
  }, [active, error, suppress, activate])
}

export function useContract(address: string | undefined, ABI: any, withSignerIfPossible = true): Contract | null {
  const { library, account } = useActiveWeb3React()

  return useMemo(() => {
    if (!address || !ABI || !library) return null
    try {
      return getContract(address, ABI, library, withSignerIfPossible && account ? account : undefined)
    } catch (error) {
      console.error('Failed to get contract', error)
      return null
    }
  }, [address, ABI, library, withSignerIfPossible, account])
}

export function useInterval(callback: any, delay: number) {
  const savedCallback = useRef<any>()

  // 保存新回调
  useEffect(() => {
    savedCallback.current = callback
  })

  // 建立 interval
  useEffect(() => {
    function tick() {
      savedCallback.current()
    }
    const id = setInterval(tick, delay)
    return () => clearInterval(id)
  }, [delay])
}

const NetworkName = {
  1: 'Ethereum Mainnet',
  5: 'Ethereum Testnet',
  11155111: 'SePolia',
}

const network_url_map = {
  1: 'https://etherscan.io',
  5: 'https://goerli.etherscan.io',
  11155111: 'https://sepolia.infura.io',
}

const network_rpc_url = {
  1: 'https://mainnet.infura.io/v3/',
  5: 'https://goerli.infura.io/v3/',
  11155111: 'https://sepolia.infura.io/v3/',
}

export function useSwitchNetwork() {
  const { chainId } = useActiveWeb3React()
  const network = window.location.host.indexOf('www.bakeryswap.org') >= 0 ? 1 : chainId === 1 ? 1 : 11155111
  const { ethereum } = window
  const web3 = getWeb3(chainId as number)
  async function switchNetwork() {
    if (network !== chainId && ethereum) {
      await ethereum
        ?.request({
          method: 'wallet_switchEthereumChain',
          params: [
            {
              chainId: web3.utils.toHex(network),
            },
          ],
        })
        .catch(async (e: any) => {
          if ((e as any).code === 4902 || (e as any).code === -32603) {
            ethereum
              ?.request({
                method: 'wallet_addEthereumChain',
                params: [
                  {
                    chainId: web3.utils.toHex(network),
                    chainName: NetworkName[network],
                    nativeCurrency: {
                      name: 'ETH',
                      symbol: 'ETH',
                      decimals: 18,
                    },
                    rpcUrls: [network_rpc_url[network]],
                    blockExplorerUrls: [network_url_map[network]],
                  },
                ],
              })
              .catch((e: any) => {
                alert(JSON.stringify(e))
              })
          } else if ((e as any).code === 4001) return
        })
      return
    }
  }

  useEffect(() => {
    switchNetwork()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
