export const emailVerification = (email: string) => {
  return /^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/gi.test(email)
}

export const isObject = function (value: any) {
  return value && Object.prototype.toString.call(value) === '[object Object]'
}

export const localStore = {
  get: (key: string) => {
    try {
      return JSON.parse(localStorage.getItem(key) as string)
    } catch (e) {
      localStorage.removeItem(key)
      return null
    }
  },
  set: (key: string, val: any) => {
    try {
      localStorage.setItem(key, JSON.stringify(val))
    } catch (e: any) {
      if (e.name === 'QuotaExceededError') {
        localStorage.removeItem(key)
      }
    }
  },
  remove: (key: string) => {
    localStorage.removeItem(key)
  },
  clear: () => {
    localStorage.clear()
  },
}
export const getAccountToken = (address?: string) => {
  const account = address || localStore.get('Bakeryswap.account')
  const token = localStore.get('Bakeryswap.token')
  return account && token && token[account] ? token[account] : null
}
export const removeAccountToken = (account: string | number) => {
  const token = localStore.get('Bakeryswap.token')
  if (account && token && token[account]) {
    delete token[account]
    localStore.set('Bakeryswap.token', token)
  }
}
export const removeToken = () => {
  localStore.remove('Bakeryswap.token')
}

export const sessionStore = {
  get: (key: string) => {
    try {
      return JSON.parse(sessionStorage.getItem(key) as string)
    } catch (e) {
      sessionStorage.removeItem(key)
    }
  },
  set: (key: string, val: any) => {
    try {
      sessionStorage.setItem(key, JSON.stringify(val))
    } catch (e: any) {
      if (e.name === 'QuotaExceededError') {
        sessionStorage.removeItem(key)
      }
    }
  },
  remove: (key: string) => {
    sessionStorage.removeItem(key)
  },
  clear: () => {
    sessionStorage.clear()
  },
}
export const StorageEmitter = {
  listeners: {} as any,
  on(event: any, fn: any) {
    ;(this.listeners[event] || (this.listeners[event] = [])).push(fn)
  },
  off(event: any, fn: any) {
    const eventList = this.listeners[event]
    eventList && eventList.length && (this.listeners[event] = eventList.filter((f: any) => f !== fn))
  },
  emit(event: any, ...arg: undefined[]) {
    this.listeners[event] &&
      this.listeners[event].forEach(
        (fn: {
          apply: (
            arg0: {
              listeners: any
              on(event: any, fn: any): void
              off(event: any, fn: any): void
              emit(event: any, ...arg: any[]): void
            },
            arg1: any[]
          ) => any
        }) => fn.apply(this, arg)
      )
  },
}

export function formatTime(leftTime: number) {
  const days = Math.floor(leftTime / (24 * 60 * 60))
  const hours = Math.floor((leftTime % (24 * 60 * 60)) / (60 * 60))
  const minutes = Math.floor((leftTime % (60 * 60)) / 60)
  const seconds = Math.floor(leftTime % 60)

  const formattedHours = String(hours).padStart(2, '0')
  const formattedMinutes = String(minutes).padStart(2, '0')
  const formattedSeconds = String(seconds).padStart(2, '0')

  const formattedTime = `${days}d:${formattedHours}h:${formattedMinutes}m:${formattedSeconds}s`

  return formattedTime
}
