// @ts-nocheck
import { ClientMetadata } from '../../w3w-types'

const isInBrowser: boolean = typeof window !== 'undefined'

function getIcons(): string[] {
  const links: HTMLCollectionOf<HTMLLinkElement> = document.getElementsByTagName('link')
  const icons: string[] = []

  for (let i = 0; i < links.length; i++) {
    const link: HTMLLinkElement = links[i]

    const rel: string | null = link.getAttribute('rel')
    if (rel) {
      if (rel.toLowerCase().indexOf('icon') > -1) {
        const href: string | null = link.getAttribute('href')

        if (href) {
          if (
            href.toLowerCase().indexOf('https:') === -1 &&
            href.toLowerCase().indexOf('http:') === -1 &&
            href.indexOf('//') !== 0
          ) {
            let absoluteHref: string = location.protocol + '//' + location.host

            if (href.indexOf('/') === 0) {
              absoluteHref += href
            } else {
              const path: string[] = location.pathname.split('/')
              path.pop()
              const finalPath: string = path.join('/')
              absoluteHref += finalPath + '/' + href
            }

            icons.push(absoluteHref)
          } else if (href.indexOf('//') === 0) {
            const absoluteUrl: string = location.protocol + href

            icons.push(absoluteUrl)
          } else {
            icons.push(href)
          }
        }
      }
    }
  }

  return icons
}

function getWindowMetadataOfAny(...args: string[]): string {
  const metaTags: HTMLCollectionOf<HTMLMetaElement> = document.getElementsByTagName('meta')

  for (let i = 0; i < metaTags.length; i++) {
    const tag: HTMLMetaElement = metaTags[i]
    const attributes: Array<string | null> = ['itemprop', 'property', 'name']
      .map((target: string) => tag.getAttribute(target))
      .filter((attr: string | null) => {
        if (attr) {
          return args.includes(attr)
        }
        return false
      })

    if (attributes.length && attributes) {
      const content: string | null = tag.getAttribute('content')
      if (content) {
        return content
      }
    }
  }

  return ''
}

function getName(): string {
  let name: string = getWindowMetadataOfAny('name', 'og:site_name', 'og:title', 'twitter:title')

  if (!name) {
    name = document.title
  }

  return name
}

function getDescription(): string {
  const description: string = getWindowMetadataOfAny('description', 'og:description', 'twitter:description', 'keywords')

  return description
}

export function getClientMetadata(): ClientMetadata | null {
  if (!isInBrowser) return

  const name: string = getName()
  const description: string = getDescription()
  const url: string = location.origin
  const icons: string[] = getIcons()

  const meta: ClientMetadata = {
    description,
    url,
    icons,
    name,
  }

  return meta
}
