import { getUrl } from './api'
import { getReq, getRequest } from './appService'
import { get, post } from '../http'
// get Whitelist
export const getWhitelist = async (chainId: number, params: any) => {
  const url = getUrl('/v1/user/whitelist/check', chainId, params)
  return await getRequest(url)
}

// check nft onsell
export const checkNftOnsell = (chainId: number, params: any, cb: any) => {
  const url = getUrl('/v1/nft/onsell', chainId, params)
  getReq(url, cb)
}

export const fetchBrcAddress = async (chainId: number, params?: any) => {
  const url = getUrl('/v1/user/brc20', chainId, params)
  return await get(url)
}

export const submitAddress = async (chainId: number, params: any) => {
  const url = getUrl('/v1/user/brc20/submit', chainId)
  return await post(url, params)
}

export const fetchInvitationCode = async (chainId: number, account?: string) => {
  const url = getUrl('/v1/user/kol/code', chainId)
  return await get(url, { account })
}

export const fetchInvitationCodes = async (chainId: number) => {
  const url = getUrl('/v1/user/kol/subcode', chainId)
  return await get(url)
}

export const submitInvitationCode = async (chainId: number, params: any) => {
  const url = getUrl('/v1/user/kol/code', chainId)
  return await post(url, params)
}

export const fetchDogexTimeConfig = async (chainId: number) => {
  const url = getUrl('/v1/dogex/user/config', chainId)
  return await get(url)
}
