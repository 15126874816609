import { ChainId } from '@uniswap/sdk-core'

export const CONTRACT_ADDRESSES: { [contract: string]: { [chainId: string]: string } } = {
  commonApi: {
    [ChainId.GÖRLI]: 'https://api.bakeryswap.io/api',
    11155111: 'https://api.bakeryswap.io/api',
    [ChainId.MAINNET]: 'https://api.bakeryswap.org/api',
  },
  bscApi: {
    [ChainId.GÖRLI]: 'https://testnet.bscscan.com',
    11155111: 'https://testnet.bscscan.com',
    [ChainId.MAINNET]: 'https://bscscan.com',
  },
}
