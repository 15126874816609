import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { useDarkModeManager } from 'state/user/hooks'
import { useV2TradeExactOut } from 'hooks/useV2Trade'
import { useAllTokens } from 'hooks/Tokens'
import { filterTokens } from 'components/SearchModal/filtering'
import { parseUnits } from '@ethersproject/units'
import JSBI from 'jsbi'
import { CurrencyAmount, Token, ETHER } from '@uniswap/sdk-core'
import { fetchAllVolume } from 'service/nftswap'
import { useActiveWeb3React } from 'hooks/index'
import { ChainId } from '@uniswap/sdk-core'

const Top = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 48px;
  z-index: 1;
  ${({ theme }) => theme.mediaWidth.upToBigerLarge`
    margin-bottom: 44px;
  `};
  ${({ theme }) => theme.mediaWidth.upToInfoLarge`
    margin-bottom: 40px;
  `};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0 16px;
    position: relative;
  `}
`

const LeftInfo = styled.div`
  display: flex;
  flex-direction: column;
  font-family: Baloo Da, 'Inter var', sans-serif;
`

const Intro = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 36px;
  ${({ theme }) => theme.mediaWidth.upToBigerLarge`
    margin-bottom: 33px;
  `};
  ${({ theme }) => theme.mediaWidth.upToInfoLarge`
    margin-bottom: 29px;
  `};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-bottom: 9px;
    flex-direction: column;
    align-items: flex-start;
  `}
`

const AvatarWrapper = styled.div`
  width: 180px;
  height: 180px;
  border-radius: 27px;
  border: 6px solid #fff;
  background-color: #fff;
  box-shadow: 0px 2px 16px 0px rgba(113, 123, 148, 0.26);
  margin-right: 23px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  ${({ theme }) => theme.mediaWidth.upToBigerLarge`
    width: 175px;
    height: 175px;
  `};
  ${({ theme }) => theme.mediaWidth.upToInfoLarge`
    width: 154px;
    height: 154px;
  `};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 86px;
    height: 86px;
    border-width: 3px;
    margin-right: 0;
    border-radius: 12px;
    margin-bottom: 6px;
    img {
      border-radius: 12px;
    }
  `}
`

const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.text1};
  font-size: 38px;
  img {
    width: 26px;
    height: 26px;
    margin-left: 20px;
  }
  margin-bottom: 14px;
  ${({ theme }) => theme.mediaWidth.upToBigerLarge`
    font-size: 35px;
    img {
      margin-left: 18px;
    }
    margin-bottom: 13px;
  `};
  ${({ theme }) => theme.mediaWidth.upToInfoLarge`
    font-size: 30px;
    img {
      width: 23px;
      height: 23px;
      margin-left: 16px;
    }
    margin-bottom: 11px;
  `};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-bottom: 9px;
    font-size: 22px;
    img {
      width: 21px;
      height: 21px;
      margin-left: 7px;
    }
  `}
`

const Desc = styled.div`
  width: 632px;
  font-size: 14px;
  color: ${({ theme }) => (theme.darkMode ? 'rgba(255, 255, 255, 0.6)' : '#AC562A')};
  margin-bottom: 6px;
  font-family: IBM Plex Sans, 'Inter var', sans-serif;
  a {
    color: #428ef1;
    text-decoration: none;
    word-break: break-all;
  }
  ${({ theme }) => theme.mediaWidth.upToBigerLarge`
    width: 585px;
    font-size: 13px;
  `};
  ${({ theme }) => theme.mediaWidth.upToInfoLarge`
    width: 538px;
    font-size: 12px;
  `};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    margin-bottom: 12px;
  `}
`

const RightInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  align-self: flex-end;
  height: 330px;
  justify-content: flex-end;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    align-items: flex-start;
    align-self: flex-start;
  height: 100%;
  `}
`

const Links = styled.div`
  display: flex;
  align-items: center;
  grid-column-gap: 30px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
  `}
`

const Link = styled.a`
  img {
    width: 30px;
    height: 30px;
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    img {
      width: 20px;
      height: 20px;
      margin-right: 11px;
    }
  `}
`

const DataCard = styled.div`
  width: 678px;
  height: 132px;
  border-radius: 15px;
  background: ${({ theme }) => (theme.darkMode ? '#2D2E32' : '#fff3e0')};
  box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.06);
  margin-top: 31px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  ${({ theme }) => theme.mediaWidth.upToBigerLarge`
    width: 616px;
    height: 120px;
    margin-top: 28px;
  `};
  ${({ theme }) => theme.mediaWidth.upToInfoLarge`
    width: 542px;
    height: 106px;
    margin-top: 25px;
  `};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100vw;
    box-shadow: none;
    margin-top: 0px;
    height: 50px;
    margin-left: -16px;
  `}
`

const DataItem = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    right: 0;
    top: 6px;
    height: 40px;
    width: 1px;
    background: ${({ theme }) => (theme.darkMode ? 'rgba(191, 192, 198, 0.2)' : 'rgb(191, 192, 198, 0.5)')};
  }

  &:last-child {
    &::after {
      display: none;
    }
  }

  &:nth-child(2) {
    padding: 0 10px;
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    &::after {
      height: 34px;
      top: 0px;
    }
  `}
`

const DataNumber = styled.div`
  color: ${({ theme }) => (theme.darkMode ? '#fff' : '#722F0D')};
  font-family: Baloo Da, 'Inter var', sans-serif;
  font-size: 24px;
  span {
    font-size: 14px;
  }
  ${({ theme }) => theme.mediaWidth.upToBigerLarge`
    font-size: 22px;
    span {
      font-size: 13px;
    }
  `};
  ${({ theme }) => theme.mediaWidth.upToInfoLarge`
    font-size: 18px;
    span {
      font-size: 12px;
    }
  `};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-bottom: 0px;
  `}
`

const DataText = styled.div`
  color: ${({ theme }) => (theme.darkMode ? 'rgba(255, 255, 255, 0.6)' : '#C58560')};
  font-family: IBM Plex Sans, 'Inter var', sans-serif;
  font-size: 14px;
  margin-top: -10px;
  ${({ theme }) => theme.mediaWidth.upToBigerLarge`
    font-size: 13px;
  `};
  ${({ theme }) => theme.mediaWidth.upToInfoLarge`
    font-size: 12px;
  `};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: -8px;
  `}
`

const PhoneLinkIcon = styled.div`
  display: none;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: block;
    position: absolute;
    right: 15px;
    top: 74px;
    width: 26px;
    height: 26px;
    img.link {
      width: 100%;
      height: 100%;
    }
  `}
`

const PhoneLinks = styled.div`
  display: none;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    &.active {
      display: flex;
    }
    position: absolute;
    top: 36px;
    right: 0;
    flex-direction: column;
    z-index: 1;
    width: 166px;
    height: 160px;
    background-color: ${theme.darkMode ? '#2D2E32' : '#fff'};
    box-shadow: 0px 0px 11.11551284790039px 0px rgba(0, 0, 0, 0.10);
    border-radius: 7px;
    grid-row-gap: 29px;
    padding: 20px 0 0 23px;
    a {
      text-decoration: none;
      color: ${theme.darkMode ? '#fff' : '#121212'};
      font-size: 14px;
      font-weight: 500;
      display: flex;
      align-items: center;
      text-transform: capitalize;
    }
  `}
`

const links = [
  {
    name: 'twitter',
    href: 'https://twitter.com/thedoggycoin',
  },
  {
    name: 'opensea',
    href: 'https://opensea.io/collection/doggynft2021',
  },
  {
    name: 'website',
    href: 'https://www.cryptodoggies.io',
  },
]
export default function Header() {
  const { chainId } = useActiveWeb3React()
  const [isDark] = useDarkModeManager()
  const [isOpenLink, setIsOpenLink] = useState(false)
  const [volume, setVolume] = useState(0)
  const [ethPrice, setEthPrice] = useState('-')
  const [parseAmount, setParseAmount] = useState<CurrencyAmount<Token>>()

  const allTokens = useAllTokens()
  const doggyToken = useMemo(() => {
    return filterTokens(Object.values(allTokens), chainId === ChainId.GÖRLI ? 'DOGX' : 'bDoggyNFT')[0]
  }, [allTokens, chainId])

  useEffect(() => {
    if (!doggyToken) return
    const typedValueParsed = parseUnits(`1`, doggyToken?.decimals).toString()
    const parseAmount = CurrencyAmount.fromRawAmount(doggyToken, JSBI.BigInt(typedValueParsed))
    setParseAmount(parseAmount)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const trade = useV2TradeExactOut(ETHER, parseAmount)?.inputAmount

  useEffect(() => {
    setEthPrice(trade?.toSignificant(2) || '-')
  }, [trade])

  const handleFetchAllVolume = async () => {
    const { data: res } = await fetchAllVolume(chainId as number)
    if (res.code === 0) {
      setVolume(Math.ceil(res.data.volume))
    }
  }

  useEffect(() => {
    handleFetchAllVolume()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chainId])

  return (
    <Top>
      <LeftInfo>
        <Intro>
          <AvatarWrapper>
            <img src="/images/nftswap/avatar.png" />
          </AvatarWrapper>
          <TextWrapper>
            Crypto Doggies
            <img src="/images/nftswap/auth.png" />
          </TextWrapper>
        </Intro>
        <Desc>
          Crypto Doggies are 10,000 unique and animated Doggy NFTs covering a variety of popular breeds such as
          Shiba-Inu, Huskie, and many more.
        </Desc>
        <Desc>
          The bToken for DoggyNFT is $DoggyNFT CA: &nbsp;
          <a
            href="https://etherscan.io/token/0xd2e4b429cbefaf2cafa9731d66600c44bac851a4"
            target="_blank"
            rel="noreferrer"
          >
            https://etherscan.io/token/0xd2e4b429cbefaf2cafa9731d66600c44bac851a4
          </a>
          <br />
          Selling Fee: 8% (burn when sell)
          <br /> Learn more: &nbsp;
          <a href="https://www.cryptodoggies.io/" target="_blank" rel="noreferrer">
            https://www.cryptodoggies.io/
          </a>
        </Desc>
      </LeftInfo>
      <PhoneLinkIcon>
        <img
          className="link"
          src={`/images/nftswap/link${isDark ? '-dark' : ''}.png`}
          onClick={() => setIsOpenLink(!isOpenLink)}
        />
        <PhoneLinks className={isOpenLink ? 'active' : ''}>
          {links.map((link) => (
            <Link href={link.href} key={link.name} target="_blank">
              <img src={`/images/nftswap/${link.name}${isDark ? '-dark' : ''}.png`} />
              {link.name}
            </Link>
          ))}
        </PhoneLinks>
      </PhoneLinkIcon>
      <RightInfo>
        <Links>
          {links.map((link) => (
            <Link href={link.href} key={link.name} target="_blank">
              <img src={`/images/nftswap/${link.name}${isDark ? '-dark' : ''}.png`} />
            </Link>
          ))}
        </Links>
        <DataCard>
          <DataItem>
            <DataNumber>10000</DataNumber>
            <DataText>Items</DataText>
          </DataItem>
          <DataItem>
            <DataNumber>
              {ethPrice}
              {ethPrice !== '-' && <span> ETH</span>}
            </DataNumber>
            <DataText>Price</DataText>
          </DataItem>
          <DataItem>
            <DataNumber>
              {volume}
              <span> ETH</span>
            </DataNumber>
            <DataText>Volume</DataText>
          </DataItem>
          <DataItem>
            <DataNumber>
              8<span> %</span>
            </DataNumber>
            <DataText>Selling Fee</DataText>
          </DataItem>
        </DataCard>
      </RightInfo>
    </Top>
  )
}
