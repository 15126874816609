import React from 'react'
import styled from 'styled-components'
import { Text } from 'rebass'

const Wrapper = styled.section<{
  top: number | string
  borderRadius: number | string
  padding: number | string
  background: string
}>`
  position: absolute;
  top: ${({ top }) => top}px;
  left: 0;
  background: ${({ background }) => background};
  border-top-right-radius: ${({ borderRadius }) => borderRadius}px;
  border-bottom-right-radius: ${({ borderRadius }) => borderRadius}px;
  color: #fffdfa;
  font-family: Baloo Da;
  font-weight: 400;
  padding: ${({ padding }) => padding};
`

interface statusMapProps {
  [key: string]: {
    [key: string]: string
  }
}
const statusMap: statusMapProps = {
  'Coming Soon': {
    name: 'Coming Soon',
    background: '#A5A5A5',
  },
  'Counting Down': {
    name: 'Counting Down',
    background: '#EB5757',
  },
  Ongoing: {
    name: 'Ongoing',
    background: '#EB5757',
  },
  Ended: {
    name: 'Ended',
    background: '#A5A5A5',
  },
}

interface sizeMapProps {
  [key: string]: {
    [key: string]: number | string
  }
}
const sizeMap: sizeMapProps = {
  sm: {
    top: 16,
    borderRadius: 12,
    fontSize: 12,
    padding: '6px 12px',
  },
  base: {
    top: 24,
    borderRadius: 16,
    fontSize: 18,
    padding: '8px 24px',
  },
}

interface IdoStatusProps {
  status: string
  size?: string
}

export default function IdoStatus({ status, size = 'base' }: IdoStatusProps) {
  return (
    <Wrapper
      top={sizeMap[size].top}
      borderRadius={sizeMap[size].borderRadius}
      padding={sizeMap[size].padding}
      background={statusMap[status].background}
    >
      <Text fontSize={sizeMap[size].fontSize}>{statusMap[status].name}</Text>
    </Wrapper>
  )
}
