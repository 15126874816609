import React, { useCallback, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { RowCenter } from '../Row'
import Loader from 'components/Loader'
import { useDarkModeManager } from 'state/user/hooks'

const Loading = styled(RowCenter)<{ align?: string; isHiddenLoading: boolean; hiddenFold: boolean }>`
  align-items: ${({ align }) => (align ? align : 'center')};
  height: 64px;
  ${({ isHiddenLoading, hiddenFold }) =>
    isHiddenLoading && {
      position: 'relative',
      bottom: '-10px',
      left: hiddenFold ? 'calc(50vw - 310px)' : 'calc(50vw - 410px)',
      marginLeft: '-50%',
    }}
  ${({ theme, isHiddenLoading }) => theme.mediaWidth.upToExtraSmall`
    left: ${isHiddenLoading ? 'calc(50vw - 16px)' : ''};
  `}
`

const Dots = styled.span`
  display: inline-flex;
  align-items: center;
  font-family: Poppins, 'Inter var', sans-serif;
  font-weight: 600;
  color: ${({ theme }) => (theme.darkMode ? theme.white : '#121212')};
  svg {
    margin-right: 4px;
  }
  &::after {
    display: inline-block;
    animation: ellipsis 1.25s infinite;
    content: '.';
    width: 1em;
    text-align: left;
  }
  @keyframes ellipsis {
    0% {
      content: '.';
    }
    33% {
      content: '..';
    }
    66% {
      content: '...';
    }
  }
`

interface InfiniteScrollProps {
  selector: string
  loading: boolean
  loadedAll: boolean
  children: React.ReactNode
  loadmore: () => void
  isHiddenLoading?: boolean
  hiddenFold?: boolean
}

const InfiniteScroll = ({
  children,
  selector,
  loading,
  loadedAll,
  loadmore,
  isHiddenLoading,
  hiddenFold,
}: InfiniteScrollProps) => {
  const loadingEl = useRef(null)
  const loadmoreEl: any = loadingEl && loadingEl.current
  const parentEl = selector ? document.querySelector(`${selector}`) : null
  const [isDark] = useDarkModeManager()
  const mainScrollBar = useCallback(() => {
    if (loading || loadedAll) return
    if (!loadmoreEl || !parentEl) return

    const scrollTop = parentEl.scrollTop
    const clientHeight = parentEl.getBoundingClientRect().height
    const offsetTop = loadmoreEl.offsetTop
    const offset = offsetTop - scrollTop

    if (offset < clientHeight) {
      loadmore()
    }
  }, [loading, loadedAll, loadmore, loadmoreEl, parentEl])

  const scrollBar = useCallback(() => {
    console.log(loadmoreEl)
    if (loading || loadedAll) return

    console.log(loadmoreEl)
    // const loadmoreEl = loadingEl && loadingEl.current
    if (!loadmoreEl) return

    const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
    const clientHeight = document.documentElement.clientHeight || document.body.clientHeight
    const offsetTop = loadmoreEl.offsetTop
    const offset = offsetTop - scrollTop

    if (offset < clientHeight) {
      loadmore()
    }
  }, [loading, loadedAll, loadmoreEl, loadmore])

  useEffect(() => {
    if (parentEl) {
      parentEl.addEventListener('scroll', mainScrollBar)
    } else {
      document.addEventListener('scroll', scrollBar)
    }
    return () => {
      if (parentEl) {
        parentEl.removeEventListener('scroll', mainScrollBar)
      } else {
        document.removeEventListener('scroll', scrollBar)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollBar, parentEl])

  return (
    <>
      {children}
      {!loadedAll && (
        <Loading ref={loadingEl} isHiddenLoading={!!isHiddenLoading} hiddenFold={!!hiddenFold}>
          <Dots>
            <Loader stroke={isDark ? '#fff' : '#121212'} />
            Loading
          </Dots>
        </Loading>
      )}
    </>
  )
}

export default InfiniteScroll
