// @ts-nocheck
import { IRpcConfig } from '../w3w-types'

import { infuraNetworks } from './constants'

export function getInfuraRpcUrl(chainId: number, infuraId?: string): string | undefined {
  let rpcUrl: string | undefined
  const network = infuraNetworks[chainId]
  if (typeof network == 'string') {
    rpcUrl = `https://${network}.infura.io/v3/${infuraId}`
  }
  return rpcUrl
}

export function safeJsonParse<T = any>(value: string): T | string {
  if (typeof value !== 'string') {
    throw new Error(`Cannot safe json parse value of type ${typeof value}`)
  }
  try {
    return JSON.parse(value)
  } catch {
    return value
  }
}

export function safeJsonStringify(value: any): string {
  return typeof value === 'string' ? value : JSON.stringify(value)
}

export function getRpcUrl(chainId: number, rpc: IRpcConfig): string | undefined {
  let rpcUrl: string | undefined
  const infuraUrl = getInfuraRpcUrl(chainId, rpc.infuraId)
  if (rpc.custom && rpc.custom[chainId]) {
    rpcUrl = rpc.custom[chainId]
  } else if (infuraUrl) {
    rpcUrl = infuraUrl
  }
  return rpcUrl
}

export function isNode() {
  return (
    typeof process !== 'undefined' &&
    typeof process.versions !== 'undefined' &&
    typeof process.versions.node !== 'undefined'
  )
}

export function normalizeChainId(chainId: string | number | bigint) {
  if (typeof chainId === 'string') return Number.parseInt(chainId, chainId.trim().substring(0, 2) === '0x' ? 16 : 10)
  if (typeof chainId === 'bigint') return Number(chainId)
  return chainId
}
