import Web3 from 'web3'
import BigNumber from 'bignumber.js'
import { Contracts } from './lib/contracts.js'
import { Account } from './lib/accounts.js'
import { EVM } from './lib/evm.js'

import { contractAddresses, deadAddress } from './lib/constants'

export class Sushi {
  constructor(provider, networkId, testing, options) {
    let realProvider

    if (typeof provider === 'string') {
      if (provider.includes('wss')) {
        realProvider = new Web3.providers.WebsocketProvider(provider, options.ethereumNodeTimeout || 10000)
      } else {
        realProvider = new Web3.providers.HttpProvider(provider, options.ethereumNodeTimeout || 10000)
      }
    } else {
      realProvider = provider
    }
    this.web3 = new Web3(realProvider)

    if (testing) {
      this.testing = new EVM(realProvider)
      this.snapshot = this.testing.snapshot()
    }

    if (options.defaultAccount) {
      this.web3.eth.defaultAccount = options.defaultAccount
    }
    this.provider = realProvider
    this.networkId = networkId
    this.contracts = new Contracts(realProvider, networkId, this.web3, options)
    this.sushiAddress = contractAddresses.sushi[networkId]
    this.masterChefAddress = contractAddresses.masterChef[networkId]
    this.wethAddress = contractAddresses.weth[networkId]
    this.wbnbAddress = contractAddresses.wbnb[networkId]
    this.deadAddress = deadAddress
    this.dishAddress = contractAddresses.dish[networkId]
    // this.dishMasterAddress = contractAddresses.dishMaster[networkId]
    this.transferAddress = contractAddresses.transfer[networkId]
    this.reviewAddress = contractAddresses.reviewAddress[networkId]
    // this.busdAddress = contractAddresses.busdToken[networkId]
    // this.usdtAddress = contractAddresses.usdtToken[networkId]
    // this.bktAddress = contractAddresses.bktToken[networkId]
    this.bakeryAddress = contractAddresses.bakeryToken[networkId]
    this.doggyAddress = contractAddresses.doggyToken[networkId]
    this.punkxAddress = contractAddresses.punkxToken[networkId]
    this.doggyCoinAddress = contractAddresses.doggyCoin[networkId]
    this.oneCatAddress = contractAddresses.oneCatToken[networkId]
    this.wBTCAddress = contractAddresses.wBTCToken[networkId]
  }

  async resetEVM() {
    this.testing.resetEVM(this.snapshot)
  }

  addAccount(address, number) {
    this.accounts.push(new Account(this.contracts, address, number))
  }

  setProvider(provider, networkId) {
    this.web3.setProvider(provider)
    this.contracts.setProvider(provider, networkId)
    this.operation.setNetworkId(networkId)
  }

  setDefaultAccount(account) {
    this.web3.eth.defaultAccount = account
    this.contracts.setDefaultAccount(account)
  }

  getDefaultAccount() {
    return this.web3.eth.defaultAccount
  }

  loadAccount(account) {
    const newAccount = this.web3.eth.accounts.wallet.add(account.privateKey)

    if (!newAccount || (account.address && account.address.toLowerCase() !== newAccount.address.toLowerCase())) {
      throw new Error(`Loaded account address mismatch.
        Expected ${account.address}, got ${newAccount ? newAccount.address : null}`)
    }
  }

  toBigN(a) {
    return BigNumber(a)
  }
}
