import React from 'react'
import styled from 'styled-components/macro'
// import SettingsTab from '../Settings'
// import { Percent } from '@uniswap/sdk-core'

import { RowFixed } from '../Row'
import { TYPE } from '../../theme'

const StyledSwapHeader = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.text2};
  font-family: 'Baloo Da', 'Inter var', sans-serif;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  height: 3rem;
`

export default function SwapHeader() {
  return (
    <StyledSwapHeader>
      <RowFixed>
        <TYPE.black fontWeight={500} fontSize={20}>
          Swap
        </TYPE.black>
      </RowFixed>
    </StyledSwapHeader>
  )
}
