import { useCallback, useEffect, useState } from 'react'
import { BigNumber } from 'bignumber.js'
import { Contract } from '@ethersproject/contracts'
import { CONTRACT_ADDRESSES } from 'constants/pet'

// hooks
import { useContract } from 'hooks/useContract'
import { useActiveWeb3React } from 'hooks'
import { IDTOKEN } from 'constants/pet'
import ERC20_ABI from 'utils/lib/abi/erc20.json'

function useTokenContract(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(tokenAddress, ERC20_ABI, withSignerIfPossible)
}

export function useTokenBalance(tokenName: string) {
  const { account, chainId, library } = useActiveWeb3React()
  const [balance, setBalance] = useState(new BigNumber(-1))

  const erc20 = useTokenContract(chainId ? CONTRACT_ADDRESSES[tokenName][chainId as number] : undefined)
  const getBalance = useCallback(async () => {
    if (account && erc20 && chainId && IDTOKEN[tokenName].includes(chainId)) {
      // const balance = await erc20.balanceOf(account)
      try {
        const balance =
          tokenName === 'eth' || tokenName === 'bnb'
            ? await library?.getBalance(account)
            : await erc20.balanceOf(account)
        setBalance(
          tokenName === 'doggyNFT'
            ? new BigNumber(balance.toString())
            : new BigNumber(balance.toString()).div(new BigNumber(10).pow(18))
        )
      } catch (e) {
        console.log(e)
      }
    }
  }, [account, erc20, tokenName, chainId, library])

  useEffect(() => {
    if (account && erc20) {
      getBalance()
    }
    const refreshInterval = setInterval(getBalance, 10000)
    return () => clearInterval(refreshInterval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, erc20])

  return balance
}
